<template>
  <div
    :style="{
      height: height + 'px',
      backgroundImage: `url(${backgroundImg})`,
    }"
    class="basic-box"
  >
    <div class="box" :style="{background: backgroundImg ? 'transparent' : background}">
      <div class="title" :style="{ color: titleColor }">{{ titleText }}</div>
      <div class="boxText">
        <slot name="text"></slot>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "basicContainer",
  props: {
    height: {},
    titleText: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "#f9f9f9",
    },
    backgroundImg: {
      type: String,
      default: "",
    },
    titleColor: {
      type: String,
      default: "#000",
    },
  },
};
</script>
<style lang="scss" scoped>
.basic-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  .box {
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
  }
  .title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    line-height: 42px;
  }
}
</style>