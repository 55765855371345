<template>
  <div>
    <el-dialog title="填写表单跟进信息" :visible.sync="showModal" width="60%" center @close="closeFun">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="客户信息" name="1">
          <div class="infoItem">
            <div class="infoTiTle">
              {{ EditData.name }}（{{ sexObj[EditData.sex] }}，<span v-if="EditData.ageRange">{{ EditData.ageRange
              }}</span>）
            </div>
            <div class="infoText">
              <p>{{ EditData.phone }}（{{ EditData.ascriptionPlace }}）</p>
              <el-button type="primary" size="mini" plain @click="copyClick(EditData.phone)">复制</el-button>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="基本信息" name="2">
          <div class="baseItem">
            <p>表单入库时间：{{ EditData.createTime }}</p>
            <p>页面停留时长：{{ EditData.scanTimeS }}</p>
          </div>
          <div class="baseItem">
            <p>
              表单来源渠道：{{ EditData.channelName }}-{{
                EditData.subChannelName
              }}
            </p>
          </div>
          <div class="baseItem">
            <p>
              投放页面链接（可点击浏览）：<a target="_blank" :href="EditData.activityUrl">{{ EditData.activityUrl }}</a>
            </p>
          </div>
        </el-collapse-item>
        <el-collapse-item title="问卷信息" name="3">
          <div class="questionItem">
            <h3>1/4 请问您是给家里哪位亲人看墓？是否需要殡仪服务？</h3>
            <p v-if="EditData.question1 && EditData.question1.length > 0">
              <span v-for="(question, index) of EditData.question1" :key="index">{{ question.key }}、</span>
            </p>
            <p v-else>暂未填写</p>
          </div>
          <div class="questionItem">
            <h3>
              2/4
              九公山墓型多样，有标准墓、艺术墓、家族自选墓，价位由1.9万至80万不等；请问您本次购墓预算大概在多少？
            </h3>
            <p v-if="EditData.question2 && EditData.question2.length > 0">
              <span v-for="(question, index) of EditData.question2" :key="index">{{ question.key }}</span>
            </p>
            <p v-else>暂未填写</p>
          </div>
          <div class="questionItem">
            <h3>3/4 请问您的年龄和性别分别是？</h3>
            <p v-if="EditData.question3 && EditData.question3.length > 0">
              <span v-for="(question, index) of EditData.question3" :key="index">{{ question.key }}</span>
            </p>
            <p v-else>暂未填写</p>
          </div>
          <div class="questionItem">
            <h3>4/4 您什么时间段方便接听电话？</h3>
            <p v-if="EditData.question4 && EditData.question4.length > 0">
              <span v-for="(question, index) of EditData.question4" :key="index">{{ question.key }}</span>
            </p>
            <p v-else>暂未填写</p>
          </div>
          <div class="questionEditItem">
            <div class="optionsItem">
              <span>预约墓园：</span>
              <el-select v-model="EditData.reservationId" filterable placeholder="请选择">
                <el-option v-for="item in reservationOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="optionsItem">
              <span class="needDriveCss">预约参观时间：</span>
              <el-date-picker v-model="EditData.visitTime" type="datetime" placeholder="选择日期时间"
                format="yyyy-MM-dd HH:mm:ss" valueFormat="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </div>
          </div>
          <div class="questionEditItem">
            <div class="optionsItem">
              <div>
                <span>是否需要专车接送：</span>
                <el-radio-group @change="changeNeedDrive" v-model="EditData.needDrive">
                  <el-radio :label="1">需要</el-radio>
                  <el-radio :label="2">不需要</el-radio>
                </el-radio-group>
              </div>
              <span class="needDriveCss">专车接客时间：</span>
              <el-date-picker v-model="EditData.driveTime" type="datetime" placeholder="选择日期时间"
                :disabled="EditData.needDrive === 2" format="yyyy-MM-dd HH:mm:ss" valueFormat="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>

            </div>
          </div>
          <div class="questionEditItem">
            <div class="optionsItem">
              <span>同行人数：</span>
              <el-select v-model="EditData.numberOfPeers" placeholder="请选择">
                <el-option v-for="item in numberOfPeersList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="questionEditItem">
            <div class="optionsItem">
              <span>客户标签：</span>
              <div class="tags" v-if="tags && tags.length > 0">
                <div class="tagItem" v-for="item of tags" :key="item.value">
                  <el-tag :type="item.tag">{{ item.label }}</el-tag>
                </div>
              </div>
              <el-button type="text" @click="addTag">更新标签</el-button>
            </div>
          </div>
          <div class="questionEditItem">
            <div class="optionsItem">
              <span>客户阶段：</span>
              <el-select v-model="EditData.progress" placeholder="请选择">
                <el-option v-for="item in progressList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="跟进记录" name="4">
          <template slot="title">
            <div class="slotTitle">
              <p>跟进记录</p>
              <el-button type="text" @click.stop="addRecord">新增跟进记录</el-button>
            </div>
          </template>
          <div class="recordsList" v-if="EditData.lastRecords && EditData.lastRecords.length > 0">
            <div class="recordsItem" v-for="(item, index) of EditData.lastRecords" :key="index">
              <div class="recordsHeader">
                <span>{{ item.adminName }}</span>
                <span>{{ item.createTime }}</span>
              </div>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showModal = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <Tags :show.sync="showTags" :edata="EditData" @emitUpdateData="emitUpdateData" />
    <LastRecord :editData="editData" :show.sync="showLastRecord" @emitSuccess="adddSuccess" />
  </div>
</template>
<script>
import Tags from "@/views/clientele/clientele/components/tags.vue";
import LastRecord from "@/views/clientele/clientele/components/lastRecord.vue";
import {
  addOrUpdateFormApi,
  queryFormByIdApi,
} from "@/api/clientele/clientele.js";
let defaultData = {
  name: "",
  sex: 2,
  ageRange: "",
  phone: "",
  ascriptionPlace: "",
  createTime: "",
  scanTime: "",
  channelName: "",
  subChannelName: "",
  activityUrl: "",
  question1: [],
  reservationId: 1,
  visitTime: "",
  needDrive: 1,
  driveTime: "",
  numberOfPeers: "", // 同行人数
  intentionTag: -1, // 客户意向tag
  invalidTag: [], // 无效tag
  targetTag: [], // 给谁看墓
  budgetTag: [], // 紧急程度
  othersTag: [], // 其他
  lastRecords: [],
};
export default {
  name: "followCom",
  components: {
    Tags,
    LastRecord,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {
        return {
          name: "周杰伦",
          sex: 0,
          ageRange: "40-45",
          phone: "13888888888",
          ascriptionPlace: "北京-北京",
          createTime: "2023-01-03 20:10",
          scanTime: "10分钟",
          channelName: "百度",
          subChannelName: "搜索",
          activityUrl: "https://gitee.com/attacking-saury",
          question1: [],
          reservationId: 1,
          visitTime: "",
          needDrive: 1,
          driveTime: "",
          numberOfPeers: 0, // 同行人数
          intentionTag: 1, // 客户意向tag
          invalidTag: [4, 6], // 无效tag
          targetTag: [11], // 给谁看墓
          budgetTag: [13, 15], // 紧急程度
          othersTag: [19, 21], // 其他
          lastRecords: [
            {
              adminId: "123",
              adminName: "zhv",
              content: "今天联系了一下",
              customerId: "123",
              createTime: "2023-01-24 14:13:12",
            },
          ],
        };
      },
    },
    editId: {
      type: String || Number,
      default: "",
    },
  },
  data() {
    return {
      showModal: false,
      activeNames: ["1", "2", "3", "4"],
      sexObj: {
        0: "男",
        1: "女",
        2: "未知",
      },
      tagObj: {
        1: "高意向",
        2: "中意向",
        3: "低意向",
        4: "非本人",
        5: "联系不上",
        6: "无意向",
        7: "空号",
        8: "同行",
        9: "自己",
        10: "在世家里人",
        11: "过世家里人",
        12: "着急购买",
        13: "需跟家里人商量",
        14: "不着急购买",
        15: "预算充足",
        16: "预算一般",
        17: "预算较低",
        18: "需要殡仪服务",
        19: "已有选墓经历",
        20: "同行带看过",
        21: "继续跟进",
        22: "放弃跟进",
      },
      reservationOptions: [
      {
        value: 1,
        label: "北京九公山纪念林",
      },
      {
        value: 2,
        label: "北京天寿园",
      },
      {
        value: 3,
        label: "北京桃峰园",
      },
      {
        value: 4,
        label: "北京景仰园",
      },
      {
        value: 5,
        label: "北京八达岭陵园",
      },
      {
        value: 6,
        label: "北京凤凰山陵园",
      }, {
        value: 7,
        label: "北京九里山公墓二区",
      }, {
        value: 8,
        label: "北京永宁陵园",
      }, {
        value: 9,
        label: "北京九龙山生态陵园",
      }, {
        value: 10,
        label: "北京归山陵园",
      }, {
        value: 11,
        label: "北京永福公墓",
      }, {
        value: 12,
        label: "北京静安公墓",
      }, {
        value: 13,
        label: "北京龙泉公墓",
      },
      {
        value: 14,
        label: "北京福安园",
      },
      {
        value: 15,
        label: "北京华夏陵园",
      },
      {
        value: 16,
        label: "北京福田公墓",
      },
      {
        value: 17,
        label: "北京佛山陵园",
      },
      {
        value: 18,
        label: "北京炎黄陵园",
      },
      {
        value: 19,
        label: "北京外侨公墓",
      },
      {
        value: 20,
        label: "北京长安园公墓",
      },
      {
        value: 21,
        label: "北京太子峪陵园",
      },
      {
        value: 22,
        label: "北京潮白陵园",
      },
      {
        value: 23,
        label: "北京万佛华侨陵园",
      },
      {
        value: 24,
        label: "北京天堂公墓",
      },
      {
        value: 25,
        label: "北京惠灵山陵园",
      },
      {
        value: 26,
        label: "北京朝阳陵园",
      }, {
        value: 27,
        label: "河北三河灵山宝塔陵园",
      },
      {
        value: 28,
        label: "河北遵化清东陵万佛园",
      },

      {
        value: 29,
        label: "河北涿州人生故事园",
      },
      {
        value: 30,
        label: "河北涿州万佛园公墓",
      },
      {
        value: 31,
        label: "河北涿州祥鹤园公墓",
      },
      {
        value: 32,
        label: "河北涿州卧龙公墓",
      },

      {
        value: 33,
        label: "河北怀来中华永久陵园",
      },
      {
        value: 34,
        label: "河北天佛园公墓",
      },
      {
        value: 35,
        label: "河北廊坊施孝陵园",
      },
      {
        value: 36,
        label: "河北廊坊万桐园公墓",
      },
      {
        value: 37,
        label: "天津玉佛寺寝宫",
      },
      {
        value: 999,
        label: "其他",
      },

      ],

      numberOfPeersList: [
        {
          value: 0,
          label: "待定",
        },
        {
          value: 1,
          label: 1,
        },
        {
          value: 2,
          label: 2,
        },
        {
          value: 3,
          label: 3,
        },
        {
          value: 4,
          label: 4,
        },
        {
          value: 5,
          label: 5,
        },
        {
          value: 6,
          label: 6,
        },
        {
          value: 7,
          label: 7,
        },
        {
          value: 8,
          label: 8,
        },
        {
          value: 9,
          label: 9,
        },
        {
          value: 10,
          label: 10,
        },
      ],
      progressList: [
        {
          value: 1,
          label: "新客户",
        },
        {
          value: 2,
          label: "已联系",
        },
        {
          value: 3,
          label: "已加企微",
        },
        {
          value: 4,
          label: "已参观",
        },
        {
          value: 5,
          label: "已成交",
        },
        {
          value: 6,
          label: "已放弃",
        },
      ],
      tags: [],
      showTags: false,
      showLastRecord: false,
      EditData: {},
    };
  },
  watch: {
    show(val) {
      this.showModal = val;
      if (val) {
        this.initFun();
      } else {
        this.closeFun();
      }
    },
  },
  computed: {},
  created() {
    // this.initFun(true);
  },
  methods: {
    async initFun(falg) {
      if (!falg) {
        await this.queryFormByIdFun();
      }
      this.tags = [];
      if (this.EditData.intentionTag) {
        this.tags.push({
          label: this.tagObj[this.EditData.intentionTag],
          value: this.EditData.intentionTag,
          tag: "",
        });
      }
      if (this.EditData.invalidTag && this.EditData.invalidTag.length > 0) {
        for (const item of this.EditData.invalidTag) {
          this.tags.push({
            label: this.tagObj[item],
            value: item,
            tag: "info",
          });
        }
      }
      if (this.EditData.targetTag && this.EditData.targetTag.length > 0) {
        for (const item of this.EditData.targetTag) {
          this.tags.push({
            label: this.tagObj[item],
            value: item,
            tag: "warning",
          });
        }
      }
      if (this.EditData.budgetTag && this.EditData.budgetTag.length > 0) {
        for (const item of this.EditData.budgetTag) {
          this.tags.push({
            label: this.tagObj[item],
            value: item,
            tag: "danger",
          });
        }
      }
      if (this.EditData.othersTag && this.EditData.othersTag.length > 0) {
        for (const item of this.EditData.othersTag) {
          this.tags.push({
            label: this.tagObj[item],
            value: item,
            tag: "success",
          });
        }
      }
    },
    queryFormByIdFun() {
      return new Promise((resolve) => {
        queryFormByIdApi({
          id: this.editId,
        })
          .then((res) => {
            let data = res.data.data;
            data.scanTimeS = Math.trunc(data.scanTime / 1000) + "秒";
            if (!data.needDrive) {
              data.needDrive = 1
            }
            this.EditData = data;
            this.EditData.newLastRecord = "";
            console.log(data, "请求来的数据");
            resolve();
          })
          .catch((err) => {
            this.EditData = defaultData;
            resolve();
          });
      });
    },
    closeFun() {
      this.showModal = false;
      this.$emit("update:show", false);
    },
    copyClick(text) {
      this.$Clipboard({
        text,
      })
        .then(() => {
          this.$message.success("复制成功");
        })
        .catch(() => {
          this.$message.error("复制失败");
        });
    },
    submit() {
      // 修复重复添加记录问题
      if (this.EditData.newLastRecord) {
        this.EditData.lastRecord = this.EditData.newLastRecord;
      } else {
        delete this.EditData.lastRecord;
      }
      addOrUpdateFormApi(this.EditData).then((res) => {
        if (res.data.data) {
          this.$emit("emit-add");
          this.closeFun();
        } else {
          this.$message.warning("更新错误,请稍候重试");
        }
      });
    },
    changeNeedDrive(val) {
      if (val === 2) {
        this.EditData.driveTime = "";
      }
    },
    addTag() {
      if (!this.activeNames.includes("4")) {
        this.activeNames.push("4");
      }
      this.showTags = true;
    },
    emitUpdateData(data) {
      this.EditData = data;
      this.initFun(true);
    },
    adddSuccess(data) {
      let { lastRecord, recordItem } = data;
      this.EditData.newLastRecord = lastRecord;
      this.EditData.lastRecords.unshift(recordItem);
    },
    addRecord() {
      this.showLastRecord = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.infoItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .infoTiTle {
    font-size: 18px;
    font-weight: bold;
  }

  .infoText {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
}

.baseItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.questionItem {
  padding-bottom: 10px;

  h3 {
    color: #888;
    font-size: 14px;
  }

  p {
    font-size: 16px;
    color: #000;
  }
}

.questionEditItem {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding-top: 20px;

  .optionsItem {
    display: flex;
    align-items: center;

    .needDriveCss {
      padding-left: 30px;
    }

    .tags {
      display: flex;
      align-items: center;

      .tagItem {
        margin-right: 10px;
      }
    }
  }
}

.recordsList {
  padding: 20px;
  background-color: #f2f2f2;

  .recordsItem {
    padding-bottom: 10px;
    border-bottom: 1px solid #999;

    .recordsHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #666666;
    }

    p {
      color: #000;
      padding-top: 6px;
    }
  }
}

.slotTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
}
</style>