import request from '@/router/axios'
// oss上传
export function uploadtApi(obj) {
  return request({
    url: 'admin/oss/upload',
    method: 'post',
    data: obj,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 分页模糊查询后台资源
export function getOssPageList(query) {
  return request({
    url: '/admin/oss/page',
    method: 'get',
    params: query
  })
}