
import { setStroe, getStore } from '../../util/store'
const side = {
  state: {
    pageRouter: [],
    activePage: [],
    pageList: getStore({
      name: 'pageList'
    }),
  },
  mutations: {
    setPageRouter(state, list) {
      state.pageRouter = list
    },
    setActivePage(state, data) {
      state.activePage = data
    },
    setPageList(state, data) {
      state.pageList = data
      setStroe({
        name: "pageList",
        content: data,
        type: 'session'
      })
    },
  },
  actions: {
    newPageRouter({ commit }, list) {
      return new Promise((resolve, reject) => {
        if (list && list.length > 0) {
          let newList = []
          for (const item of list) {
            if (!item.meta?.isNotTab) {
              newList.push(item)
            }
          }
          commit('setPageRouter', newList)
          resolve(newList)
        } else {
          reject('设置失败')
        }
      })
    },
    newPageList({ commit }, list) {
      return new Promise((resolve, reject) => {
        if (list && list.length > 0) {
          commit('setPageList', list)
          resolve(list)
        } else {
          reject('设置失败')
        }
      })
    }
  },
}
export default side