
export const tableOption = {
  border: true,
  index: true,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  align: 'center',
  searchMenuSpan: 6,
  highlightCurrentRow: true,
  menuWidth: 300,
  viewBtn: false,
  delBtn: true,
  addBtn: true,
  tip: false,
  columnBtn: false,
  menu: true,
  searchLabelWidth: 100,
  addBtnText: '新建账号',
  refreshBtn: true,
  column: [
    {
      type: 'input',
      label: '账号ID',
      prop: 'id',
      addDisplay: false,
      editDisplay: false
    },
    {
      type: 'input',
      label: '账号名称',
      prop: 'username',
      search: true,
      rules: [{
        required: true,
        message: "请输入账号名称",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: '绑定手机号',
      prop: 'phone',
      search: true,
      maxlength: 11,
      rules: [{
        required: true,
        message: "请输入绑定手机号",
        trigger: "blur"
      }]
    },
    {
      type: 'select',
      label: '账号角色',
      prop: 'roleNames',
      addDisplay: false,
      editDisplay: false,
      slot: true
    },
    {
      type: 'select',
      label: '账号角色',
      prop: 'roleIds',
      dicUrl: '/admin/role/listAll',
      dicFormatter: (data)=> {
        let res = data.data
        for (const item of res) {
          item.label = item.name
          item.value = item.id
        }
        return res
      },
      hide: true,
      rules: [{
        required: true,
        message: "请选择账号角色",
        trigger: "blur"
      }]
    },

    {
      type: 'input',
      label: '创建时间',
      prop: 'createTime',
      addDisplay: false,
      editDisplay: false
    },
    {
      type: 'select',
      label: '账号状态',
      prop: 'status',
      dicData: [
        {
          "label": "已禁用",
          "value": 0
        },
        {
          "label": "已启用",
          "value": 1
        },
      ],
      rules: [{
        required: true,
        message: "请选择账号状态",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: '密码',
      prop: 'password',
      editDisplay: false,
      hide: true,
      rules: [{
        required: true,
        message: "请输入密码",
        trigger: "blur"
      }]
    },
    // {
    //   type: 'input',
    //   label: '页面属性',
    //   prop: 'type',
    //   slot: true
    // },
  ]
}
