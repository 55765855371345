<template>
  <Basic-Container>
    <avue-crud
      class="avueCrud"
      ref="crud"
      v-model="crudModel"
      :page.sync="page"
      :data="tableData"
      @row-save="rowSave"
      @row-update="rowUpdate"
      @row-del="rowDel"
      @search-change="searchChange"
      @size-change="sizeChange"
      @current-change="currentChange"
      @refresh-change="refreshChange"
      :before-open="beforeOpen"
      :table-loading="tableLoading"
      :option="tableOption"
    >
      <template v-slot:roleNames="{ row }">
        <el-tag v-for="(tag, index) of row.roleNames" :key="index">{{
          tag
        }}</el-tag>
      </template>
    </avue-crud>
  </Basic-Container>
</template>
<script>
import { getUserListApi, postEditApi, deleteApi } from "@/api/user/manage.js";
import { tableOption } from "@/views/user/manage/tableOption.js";
export default {
  name: "userManage",
  components: {},
  data() {
    return {
      searchForm: {},
      tableLoading: false,
      tableData: [],
      tableOption: tableOption,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50, 100],
      },
      selectData: {},
      crudModel: {},
    };
  },
  created() {
    this.getList(this.page);
  },
  computed: {},
  methods: {
    getList(page, params) {
      this.tableLoading = true;
      getUserListApi(
        Object.assign(
          {
            current: page.currentPage,
            size: page.pageSize,
          },
          params,
          this.searchForm
        )
      )
        .then((response) => {
          let data = response.data.data;
          this.tableData = data.records || [];
          this.page.total = Number(data.total) || 0;
          this.tableLoading = false;
        })
        .catch(() => {
          this.$message.error("查询失败");
          this.tableLoading = false;
        });
      // getUserListApi()
      //   .then((res) => {
      //     let data = res.data.data || [];
      //     this.tableData = data;
      //     // this.page.total = response.total;
      //     this.tableLoading = false;
      //     if (this.tableData.length < 1) {
      //       this.$message.warning("暂无数据");
      //     }
      //   })
      //   .catch(() => {
      //     this.tableLoading = false;
      //   });
    },
    searchChange(form, done) {
      this.searchForm = form;
      this.page.currentPage = 1;
      this.getList(this.page, form);
      done();
    },
    sizeChange(pageSize) {
      this.page.currentPage = 1;
      this.page.pageSize = pageSize;
      this.getList(this.page);
    },
    currentChange(current) {
      this.page.currentPage = current;
      this.getList(this.page);
    },
    searchResetFn() {},
    rowSave(form, done, loading) {
      loading();
      let params = JSON.parse(JSON.stringify(form));
      params.roleIds = [params.roleIds];
      delete params.roleNames;
      postEditApi(params)
        .then((res) => {
          this.getList(this.page);
          this.$message.success("新增成功");
          done();
        })
        .catch((err) => {
          this.$message.error("新增失败");
        });
    },
    rowUpdate(form, index, done, loading) {
      let params = JSON.parse(JSON.stringify(form));
      params.roleIds = [params.roleIds];
      delete params.roleNames;
      postEditApi(params)
        .then((res) => {
          console.log("在这");
          this.$message.success("修改成功");
          this.getList(this.page);
          done();
        })
        .catch((err) => {
          loading();
          this.$message.warning("网络错误,请稍候重试");
        });
    },
    refreshChange() {
      this.getList(this.page);
    },
    beforeOpen(done, type) {
      if (type === "add") {
        this.$nextTick(() => {
          setTimeout(() => {}, 0);
        });
      } else {
        this.crudModel.roleIds &&
          (this.crudModel.roleIds = Number(this.crudModel.roleIds.join()));
      }
      done();
    },
    // 删除
    rowDel(form, index) {
      this.$confirm("是否删除当前用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteApi(form.id)
            .then((res) => {
              this.$message.success("删除成功");
              this.getList(this.page);
            })
            .catch((err) => {
              console.log(err, "错误");
              this.$message.warning("网络错误,请稍候重试");
            });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
</style>