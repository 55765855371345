<template>
  <div class="notPage">
    <img src="@/assets/img/browser-404.png">
    <p class="text">页面不存在</p>
    <div class="btns">
        <el-button type="primary" @click="goHome" icon="el-icon-house" >回首页</el-button>
        <el-button type="success" @click="goBack" icon="el-icon-s-fold" >返回上一页</el-button>
    </div>
  </div>
</template>
<script>

export default {
  name: 'notPage',
  components: {},
  data() {
    return {
      
    }
  },
  created() {

  },
  computed: {

  },
  methods: {
    goHome() {
      this.$router.push({
        path: '/'
      })
    },
    goBack() {
      this.$router.back()
    }
  }
}
</script>
<style lang="scss" scoped>
  .notPage {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .text {
      font-size: 30px;
      padding-top: 10px;
      font-weight: bold;
    }
    .btns {
      display: flex;
      width: 270px;
      justify-content: space-between;
      padding-top: 30px;
    }
  }
</style>