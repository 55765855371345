<template>
  <div>
    <el-dialog
      title="客户标签"
      :visible.sync="showModal"
      width="60%"
      center
      append-to-body
      @close="closeFun"
    >
      <div class="tagsBox">
        <h3 class="tagsTitle">客户意向：</h3>
        <div class="tagsItem">
          <el-radio-group v-model="tagsData.intentionTag">
            <el-radio-button :label="1">高意向</el-radio-button>
            <el-radio-button :label="2">中意向</el-radio-button>
            <el-radio-button :label="3">低意向</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="tagsBox">
        <h3 class="tagsTitle">无效：</h3>
        <div class="tagsItem">
          <el-checkbox-group v-model="tagsData.invalidTag">
            <el-checkbox-button
              v-for="item of invalidTagList"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>
      <div class="tagsBox">
        <h3 class="tagsTitle">给谁看墓：</h3>
        <div class="tagsItem">
          <el-checkbox-group v-model="tagsData.targetTag">
            <el-checkbox-button
              v-for="item of targetTagList"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>
      <div class="tagsBox">
        <h3 class="tagsTitle">购买紧急程度和预算：</h3>
        <div class="tagsItem">
          <el-checkbox-group v-model="tagsData.budgetTag">
            <el-checkbox-button
              v-for="item of budgetTagList"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>
      <div class="tagsBox">
        <h3 class="tagsTitle">其他：</h3>
        <div class="tagsItem">
          <el-checkbox-group v-model="tagsData.othersTag">
            <el-checkbox-button
              v-for="item of othersTagList"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showModal = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "tagsCom",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    edata: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showModal: false,
      tagsData: {},

      invalidTagList: [
        {
          label: "非本人",
          value: 4,
        },
        {
          label: "联系不上",
          value: 5,
        },
        {
          label: "无意向",
          value: 6,
        },
        {
          label: "空号",
          value: 7,
        },
        {
          label: "同行",
          value: 8,
        },
      ],
      targetTagList: [
        {
          label: "给自己看",
          value: 9,
        },
        {
          label: "在世家里人",
          value: 10,
        },
        {
          label: "过世家里人",
          value: 11,
        },
      ],
      budgetTagList: [
        {
          label: "着急购买",
          value: 12,
        },
        {
          label: "需跟家里人商量",
          value: 13,
        },
        {
          label: "不着急购买",
          value: 14,
        },
        {
          label: "预算充足",
          value: 15,
        },
        {
          label: "预算一般",
          value: 16,
        },
        {
          label: "预算较低",
          value: 17,
        },
      ],
      othersTagList: [
        {
          label: "需要殡仪服务",
          value: 18,
        },
        {
          label: "已有选墓经历",
          value: 19,
        },
        {
          label: "同行带看过",
          value: 20,
        },
        {
          label: "继续跟进",
          value: 21,
        },
        {
          label: "放弃跟进",
          value: 22,
        },
      ],
    };
  },
  watch: {
    show(val) {
      this.showModal = val;
      if (val) {
        this.initFun();
      } else {
        this.closeFun();
      }
    },
  },
  computed: {},
  created() {
    this.initFun();
  },
  methods: {
    initFun() {
      let data = JSON.parse(JSON.stringify(this.edata));
      if(!data.invalidTag) {
        data.invalidTag = []
      }
      if(!data.targetTag) {
        data.targetTag = []
      }
      if(!data.budgetTag) {
        data.budgetTag = []
      }
      if(!data.othersTag) {
        data.othersTag = []
      }
      this.tagsData = data
      console.log(this.tagsData ,'this.tagsData ');
    },
    closeFun() {
      this.showModal = false;
      this.$emit("update:show", false);
    },
    submit() {
      let arr = [4,5,6,7,8]
      let newArr = []
      for (const item of this.tagsData.invalidTag) {
        if(arr.includes(item)) {
          newArr.push(item)
        }
      }
      this.tagsData.invalidTag = newArr
      this.$emit("emitUpdateData", this.tagsData);
      this.closeFun();
      console.log(this.tagsData, "活动价格黑科技");
    },
  },
};
</script>
<style lang="scss" scoped>
.tagsBox {
  .tagsTitle {
    font-size: 16px;
    color: #000;
    font-weight: 400;
  }
  .tagsItem /deep/ {
    padding: 20px 0;
    .el-checkbox-button {
      border: 1px solid #dcdfe6;
      margin-right: 20px;
      border-radius: 4px;
      .el-checkbox-button__inner {
        border: none;
      }
    }
  }
}
</style>