/**
 * 自定义代码高亮插件
 */
import hljs from 'highlight.js'
import 'highlight.js/styles/vs.css'

const install = function (Vue) {
  Vue.directive('zhvHight', {
    deep: true,
    bind(el, binding) {
      let targets = el.querySelectorAll('code')

      targets.forEach(target => {
        if (typeof binding.value === 'string') {
          // if a value is directly assigned to the directive, use this
          // instead of the element content.
          target.textContent = binding.value
        }
        hljs.highlightBlock(target)
      })
    },
    componentUpdated(el, binding) {
      // after an update, re-fill the content and then highlight
      let targets = el.querySelectorAll('code')
      targets.forEach(target => {
        if (typeof binding.value === 'string') {
          // if a value is directly assigned to the directive, use this
          // instead of the element content.
          target.textContent = binding.value
          hljs.highlightBlock(target)
        }
      })
    },
  })

}
/**
 *  1.如果你需要高亮的代码是一个变量值，那么你可以这样使用它。 其中 sourcecode 为变量。
*       <pre v-highlight="sourcecode"><code></code></pre>
 *  2.如果你需要高亮的代码固定的源代码，那么你可以这样使用它。
*       <pre v-highlight><code>const s = new Date().toString()</code></pre>
 */


if (window.Vue) {
  window['highlight'] = hljs
  Vue.use(install) // eslint-disable-line
}

export default install