<template>
  <div>
    <el-dialog
      title="表单客户分配"
      :visible.sync="showModal"
      width="60%"
      center
      append-to-body
      @close="closeFun"
    >
      <div v-if="roleData && roleData.length > 0">
        <div class="tagsBox" v-for="role of roleData" :key="role.roleId">
          <h3 class="tagsTitle">{{ role.roleName }}</h3>
          <div class="tagsItem">
            <el-radio-group v-model="adminId">
              <el-radio-button
                :label="user.adminId"
                v-for="user of role.umsRoleDTOS"
                :key="user.adminId"
                >{{ user.adminName }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showModal = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryFormByIdApi,
  distributionAdminApi
} from "@/api/clientele/clientele.js";
import { getAllRoleAndAdminApi } from "@/api/user/role.js";
export default {
  name: "distributeCom",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    editAdminId: {
      type: String,
      default: "",
    },
    editId: {
      type: String || Number,
      default: "",
    },
  },
  data() {
    return {
      showModal: false,
      roleData: [],
      adminId: "",
    };
  },
  watch: {
    show(val) {
      this.showModal = val;
      if (val) {
        this.initFun();
        this.adminId = this.editAdminId;
      } else {
        this.closeFun();
      }
    },
  },
  computed: {},
  created() {},
  methods: {
    async initFun() {
      await this.queryFormByIdFun();
      getAllRoleAndAdminApi().then((res) => {
        let data = res.data.data || [];
        this.roleData = data;
      });
    },
    queryFormByIdFun() {
      return new Promise((resolve) => {
        queryFormByIdApi({
          id: this.editId,
        })
          .then((res) => {
            let data = res.data.data;
            this.adminId = data.adminId;
            console.log(data, "请求来的数据");
            resolve();
          })
          .catch((err) => {
            resolve();
          });
      });
    },
    closeFun() {
      this.showModal = false;
      this.$emit("update:show", false);
    },
    submit() {
      distributionAdminApi({
        customerFormId: this.editId,
        adminId: this.adminId,
      })
        .then((res) => {
          console.log(res, "hkk");
          if (res.data.data) {
            this.$message.success("分配成功");
            this.$emit('emit-submit')
          } else {
            this.$message.error("分配失败");
          }
          this.closeFun();
        })
        .catch((err) => {
          this.closeFun();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.tagsBox {
  .tagsTitle {
    font-size: 16px;
    color: #000;
    font-weight: 400;
  }
  .tagsItem /deep/ {
    padding: 20px 0;
    .el-radio-button {
      border: 1px solid #dcdfe6;
      margin-right: 20px;
      border-radius: 4px;
      .el-radio-button__inner {
        border: none;
      }
    }
  }
}
</style>