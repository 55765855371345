<template>
  <Layout>
    <Basic-Container>
      <avue-crud
        class="avueCrud"
        ref="crud"
        :page.sync="page"
        :data="tableData"
        @row-save="rowSave"
        @row-update="rowUpdate"
        @search-change="searchChange"
        @size-change="sizeChange"
        @current-change="currentChange"
        @refresh-change="refreshChange"
        :table-loading="tableLoading"
        :option="tableOption"
      >
        <template v-slot:createTime="{ row }">
          <div>{{ row.createTime }}</div>
        </template>
        <template v-slot:distributionTime="{ row }">
          <div>{{ row.distributionTime }}</div>
        </template>
        <template v-slot:ageRange="{ row }">
          <div>{{ row.ageRange || "—" }}</div>
        </template>
        <template v-slot:question1="{ row }">
          <div
            class="questionShow"
            v-if="row.question1 && row.question1.length > 0"
          >
            <div
              class="questionTag"
              v-for="(tag, index) of row.question1"
              :key="index"
            >
              <el-tag type="success">{{ tag.key }}</el-tag>
            </div>
          </div>
          <div v-else>暂未填写</div>
        </template>
        <template v-slot:question2="{ row }">
          <div v-if="row.question2 && row.question2.length > 0">
            <div
              class="questionTag"
              v-for="(tag, index) of row.question2"
              :key="index"
            >
              <el-tag type="success">{{ tag.key }}</el-tag>
            </div>
          </div>
          <div v-else>暂未填写</div>
        </template>
        <template v-slot:question3="{ row }">
          <div
            class="questionShow"
            v-if="row.question3 && row.question3.length > 0"
          >
            <div
              class="questionTag"
              v-for="(tag, index) of row.question3"
              :key="index"
            >
              <el-tag type="success">{{ tag.key }}</el-tag>
            </div>
          </div>
          <div v-else>暂未填写</div>
        </template>
        <template v-slot:question4="{ row }">
          <div v-if="row.question4 && row.question4.length > 0">
            <div
              class="questionTag"
              v-for="(tag, index) of row.question4"
              :key="index"
            >
              <el-tag type="success">{{ tag.key }}</el-tag>
            </div>
          </div>
          <div v-else>暂未填写</div>
        </template>
        <template v-slot:clentTag="{ row }">
          <div class="">{{ row | filterTag }}</div>
        </template>
        <template v-slot:driveTime="{ row }">
          <span>{{
            row.needDrive == 2
              ? "不需要专车"
              : row.driveTime
              ? row.driveTime
              : "暂未填写"
          }}</span>
        </template>
        <template v-slot:activityUrl="{ row }">
          <el-button type="text" @click="activityUrlClick(row.activityUrl)">{{
            row.activityUrl
          }}</el-button>
        </template>
        <template v-slot:channelId="{ row }">
          <div>{{ row.channelName }}</div>
        </template>
        <template v-slot:subChannelCode="{ row }">
          <div>{{ row.subChannelName }}</div>
        </template>
        <template v-slot:sourceType="{ row }">
          <div>
            {{
              row.sourceType == "pc"
                ? "PC端"
                : row.sourceType == "mobile"
                ? "移动端"
                : "--"
            }}
          </div>
        </template>
        <template slot-scope="{ size, row }" slot="menu">
          <el-button plain :size="size" type="primary" @click="followInfo(row)"
            >完善跟进信息</el-button
          >
          <el-button
            plain
            :size="size"
            type="danger"
            @click="distributeClick(row)"
            >客户分配</el-button
          >
        </template>
      </avue-crud>
      <FollowModal
        :editId="editId"
        :show.sync="showFollowModal"
        @emit-add="emitAdd"
      />
      <DistributeModal
        :editId="editId"
        :editAdminId="adminId"
        :show.sync="showDistributeModal"
        @emit-submit="refreshChange"
      />
    </Basic-Container>
  </Layout>
</template>
<script>
const intentionTag = {
  1: "高意向",
  2: "中意向",
  3: "低意向",
};
const invalidTag = {
  4: "非本人",
  5: "联系不上",
  6: "无意向",
  7: "空号",
  8: "同行",
};
const targetTag = {
  9: "给自己看",
  10: "在世家里人",
  11: "过世家里人",
};
const budgetTag = {
  12: "着急购买",
  13: "需跟家里人商量",
  14: "不着急购买",
  15: "预算充足",
  16: "预算一般",
  17: "预算较低",
};
const othersTag = {
  18: "需要殡仪服务",
  19: "已有选墓经历",
  20: "同行带看过",
  21: "继续跟进",
  22: "放弃跟进",
};
import { getClientListApi } from "@/api/clientele/clientele.js";
import { tableOption } from "@/views/clientele/tableOption.js";
import FollowModal from "@/views/clientele/clientele/components/follow.vue";
import DistributeModal from "@/views/clientele/clientele/components/distribute.vue";
export default {
  name: "clientele",
  components: {
    FollowModal,
    DistributeModal,
  },
  filters: {
    filterTag(row) {
      let str = "";
      if (row.intentionTag) {
        str += intentionTag[row.intentionTag] + "、";
      }
      if (row.invalidTag && row.invalidTag.length > 0) {
        for (const item of row.invalidTag) {
          str += invalidTag[item] + "、";
        }
      }
      if (row.targetTag && row.targetTag.length > 0) {
        for (const item of row.targetTag) {
          str += targetTag[item] + "、";
        }
      }
      if (row.budgetTag && row.budgetTag.length > 0) {
        for (const item of row.budgetTag) {
          str += budgetTag[item] + "、";
        }
      }
      if (row.othersTag && row.othersTag.length > 0) {
        for (const item of row.othersTag) {
          str += othersTag[item] + "、";
        }
      }
      if (str.length > 0) {
        str = str.slice(0, -1);
      }
      return str;
    },
  },
  data() {
    return {
      searchForm: {},
      tableLoading: false,
      tableData: [],
      tableOption: tableOption,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50, 100],
      },
      selectData: {},
      showFollowModal: false,
      editData: {},
      editId: "",
      showDistributeModal: false,
      adminId: "",
    };
  },
  created() {
    this.getList(this.page);
  },
  computed: {},
  methods: {
    getList(page, params) {
      this.tableLoading = true;
      getClientListApi(
        Object.assign(
          {
            current: page.currentPage,
            size: page.pageSize,
          },
          params,
          this.searchForm
        )
      )
        .then((response) => {
          let data = response.data.data;
          if (data.records && data.records.length > 0) {
            for (const item of data.records) {
              if (item.scanTime) {
                item.scanTimeS = Math.trunc(item.scanTime / 1000) + "秒";
              }
            }
          }
          this.tableData = data.records || [];
          this.page.total = Number(data.total) || 0;
          this.tableLoading = false;
        })
        .catch(() => {
          this.$message.error("查询失败");
          this.tableLoading = false;
        });
    },
    searchChange(form, done) {
      if (form.createTime) {
        form.startTime = form.createTime[0];
        form.endTime = form.createTime[1];
        delete form.createTime;
      }
      if (form.distributionTime) {
        form.distributionStartTime = form.distributionTime[0];
        form.distributionEndTime = form.distributionTime[1];
        delete form.distributionTime;
      }
      this.searchForm = form;
      this.page.currentPage = 1;
      this.getList(this.page, form);
      done();
    },
    sizeChange(pageSize) {
      this.page.currentPage = 1;
      this.page.pageSize = pageSize;
      this.getList(this.page);
    },
    currentChange(current) {
      this.page.currentPage = current;
      this.getList(this.page);
    },
    searchResetFn() {},
    rowSave(form, done, loading) {
      loading();
      let params = JSON.parse(JSON.stringify(form));
      addOrUpdateApi(params)
        .then((res) => {
          this.getList(this.page);
          this.$message.success("新增成功");
          done();
        })
        .catch((err) => {
          this.$message.error("新增失败");
        });
    },
    rowUpdate(form, index, done, loading) {
      let params = JSON.parse(JSON.stringify(form));
      addOrUpdateApi(params)
        .then((res) => {
          this.$message.success("修改成功");
          this.getList(this.page);
          done();
        })
        .catch((err) => {
          loading();
          this.$message.warning("网络错误,请稍候重试");
        });
    },
    refreshChange() {
      this.getList(this.page);
    },
    followInfo(row) {
      this.editId = row.id;
      this.showFollowModal = true;
    },
    distributeClick(row) {
      this.editId = row.id;
      this.adminId = row.adminId;
      this.showDistributeModal = true;
    },
    emitAdd() {
      this.refreshChange();
    },
    activityUrlClick(url) {
      this.$Clipboard({
        text: url,
      })
        .then(() => {
          this.$confirm("链接已经复制,是否在新标签页打开此链接?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            window.open(url);
          });
        })
        .catch(() => {
          this.$message.error("复制失败");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.questionTag {
  padding-bottom: 10px;
}
.questionTag:last-of-type {
  padding-bottom: 0;
}
.questionShow {
  display: flex;
  justify-content: center;
  .questionTag {
    margin-right: 10px;
  }
  // align-items: center;
}
</style>
