<template>
  <div class="page-css">
    <div class="logo-item">众兴未来后台管理系统</div>
    <el-menu
      :default-active="activePage.path"
      class="el-menu-vertical-demo"
      background-color="#333"
      text-color="#fff"
      active-text-color="#07f3ff"
      unique-opened
      router
    >
      <Zhvmenu :menu="asideList" />
    </el-menu>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Zhvmenu from "./menu.vue";
export default {
  components: {
    Zhvmenu,
  },
  data() {
    return {
      navList: {},
    };
  },
  computed: {
    ...mapGetters(["activePage", "pageRouter"]),
    asideList() {
      for (const item of this.pageRouter) {
        if (item.children && item.children.length > 0) {
          item.hasChildren = true;
        }
      }
      return this.pageRouter;
    },
  },
  mounted() {},
};
</script>
<style lang="scss"  scoped>
.el-menu-vertical-demo {
  width: 200px;
  height: calc(100vh - 60px);
}
.page-css /deep/ {
  height: 100vh;
  /* text-align: center; */
  .el-menu-item.isActive {
    background: rgba(0, 0, 0, 0.3) !important;
  }
}
.logo-item {
  height: 60px;
  background-color: #333;
  color: #fef300;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  font-weight: bold;
}
.menu-item-css {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}
.menu-item-css i {
  transform: translateY(-2px);
}
.son-item.menu-item-css {
  padding-left: 30px;
}
</style>