import request from '@/router/axios'

// 获取渠道列表
export function getSubChannelListApi(query) {
  return request({
    url: '/admin/subChannel/page',
    method: 'get',
    params: query
  })
}

// 添加或修改渠道
export function addOrUpdateApi(obj) {
  return request({
    url: '/admin/subChannel/addOrUpdate',
    method: 'post',
    data: obj
  })
}
export function getChannelApi(query) {
  return request({
    url: '/admin/channel/list',
    method: 'get',
    params: query
  })
}
