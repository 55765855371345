import request from '@/router/axios'


// 登录之后返回token
// https://test-api.zhxweilai.com/doc.html#/default/%E5%90%8E%E5%8F%B0%E7%94%A8%E6%88%B7%E7%AE%A1%E7%90%86/loginUsingPOST
export function postApiLogin(obj) {
  return request({
    url: '/admin/login',
    method: 'post',
    data: obj
  })
}

// /admin/info
export function getUserInfoApi(query) {
  return request({
    url: '/admin/info',
    method: 'get',
    params: query
  })
}
// /admin/logout
export function postLogoutApi(obj) {
  return request({
    url: '/admin/logout',
    method: 'post',
    data: obj
  })
}