
export const tableOption = {
  border: true,
  index: true,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  align: 'center',
  searchMenuSpan: 6,
  highlightCurrentRow: true,
  menuWidth: 300,
  viewBtn: false,
  delBtn: false,
  addBtn: false,
  tip: false,
  columnBtn: false,
  menu: false,
  searchLabelWidth: 100,
  labelWidth: 100,
  refreshBtn: true,
  column: [
    {
      type: 'datetimerange',
      label: '日期',
      prop: 'date',
      addDisplay: false,
      search: true,
      editDisplay: false,
      searchSpan: 6,
      searchRange: true,
      searchClearable: false,
      defaultTime: ['00:00:00', '23:59:59'],
      format: 'yyyy-MM-dd HH:mm:ss',                  // 这是组件展示的日期格式
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      slot: true,
      width: 100
    },
    {
      type: 'select',
      label: '渠道',
      filterable: true,
      prop: 'channelCode',
      dicUrl: '/admin/channel/list',
      props: {
        label: 'channelName',
        value: 'channelCode'
      },
      search: true,
      dicFormatter: (data) => {
        let newData = data.data
        newData.push({
          channelName: '后台录入-新增表单',
          id: '-1',
          channelCode: '-1'
        })
        return newData
      },
      width: 130,
      slot: true
    },
    {
      type: 'select',
      filterable: true,
      label: '渠道编码',
      prop: 'channelCode',
      width: 130,
    },
    {
      type: 'select',
      filterable: true,
      label: '子渠道',
      prop: 'subChannelCode',
      dicUrl: '/admin/subChannel/list',
      props: {
        label: 'channelName',
        value: 'channelCode'
      },
      search: true,
      width: 130,
      hide: true
    },
    {
      type: 'input',
      label: '子渠道',
      prop: 'subChannelName',
      width: 130
    },
    {
      type: 'input',
      label: '子渠道编码',
      prop: 'subChannelCode1',
      width: 130
    },
    {
      type: 'input',
      label: '来源端',
      prop: 'sourceType',
      width: 80,
      slot: true
    },
    {
      type: 'select',
      label: '活动名称',
      prop: 'activityId',
      dicUrl: '/admin/activity/list',
      props: {
        label: 'activityName',
        value: 'id'
      },
      search: true,
      maxlength: 18,
      span: 13,
      width: 80,
    },
    {
      type: 'input',
      label: '投放活动页UV',
      prop: 'activityPageUv',
      width: 130
    },
    {
      type: 'input',
      label: '表单提交数',
      prop: 'formSubmitCount',
      width: 130
    },
    {
      type: 'input',
      label: '重复提交表单数',
      prop: 'repeatCustomerCount',
      width: 130
    },
    {
      type: 'input',
      label: '去重表单提交数',
      prop: 'noRepeatFormSubmitCount',
      width: 130
    },
    {
      type: 'input',
      label: '表单提交率',
      prop: 'formSubmitRate',
      width: 130
    },
    {
      type: 'input',
      label: '表单去重提交率',
      prop: 'noRepeatFormSubmitRate',
      width: 130
    },
    {
      type: 'input',
      label: '重复提交占比',
      prop: 'repeatSubmitRate',
      width: 130
    },
    {
      type: 'input',
      label: '在线客服点PV',
      prop: 'customerServiceUv',
      width: 130
    },
    {
      type: 'input',
      label: '电话咨询点PV',
      prop: 'phoneUv',
      width: 130
    },
    {
      type: 'input',
      label: '点击播放视频PV',
      prop: 'playVideoUv',
      width: 130
    },
    {
      type: 'input',
      label: '问卷完成人数UV',
      prop: 'questionCompleteUv',
      width: 130
    },
    {
      type: 'input',
      label: '问卷完成率',
      prop: 'questionCompleteRate',
      width: 130
    },
    {
      type: 'input',
      label: 'IP重复率',
      prop: 'ipRepeatRate',
      width: 130
    },

  ]
}
