<template>
  <Layout>
    <Basic-Container>
      <FileList/>
    </Basic-Container>
  </Layout>
</template>
<script>
export default {
  name: "filePage",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
</style>