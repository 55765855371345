<template>
  <div id="app">
    <transition name="slide">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>

export default {};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
}
#nav {
  padding: 30px;
}
.code_box {
  width: 70%;
}
.G-noteText {
  padding-top: 30px;
  font-size: 18px;
  line-height: 28px;
  color: #999;
  font-weight: bold;
  text-indent: 2em;
}
.code-tip {
  background-color: rgb(0, 0, 0, 0.8);
  color: #d19a66;
  padding: 0 4px;
}
</style>
