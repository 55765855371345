import request from '@/router/axios'

// 获取所有角色
export function getRoleListApi(query) {
  return request({
    url: '/admin/role/page',
    method: 'get',
    params: query
  })
}
// 新增角色
export function postAddRoleApi(obj) {
  return request({
    url: '/admin/role',
    method: 'post',
    data: obj
  })
}
// 删除角色
export function deleteRoleApi(id) {
  return request({
    url: `/admin/role/${id}`,
    method: 'delete',
  })
}
// 修改角色
export function postUpdateRoleApi(obj) {
  return request({
    url: `/admin/role`,
    method: 'put',
    data: obj
  })
}

export function delObj(id) {
  return request({
    url: '/mms/merchant/' + id,
    method: 'delete'
  })
}

export function putObj(obj) {
  return request({
    url: '/mms/merchant',
    method: 'put',
    data: obj
  })
}
// 获取所有角色和角色下的用户信息
export function getAllRoleAndAdminApi() {
  return request({
    url: '/admin/role/getAllRoleAndAdmin',
    method: 'get',
  })
}
