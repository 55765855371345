<template>
  <PageNav>
    <template v-slot:main>
      <router-view/>
      <slot></slot>
    </template>
  </PageNav>
</template>
<script>
import PageNav from "@/components/pageNav/index.vue";
export default {
  name: "pageName",
  components: {
    PageNav,
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
</style>