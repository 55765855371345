<template>
  <div class="box-wrap">
    <div class="box-left">
      <PageAside />
    </div>
    <div class="box-right">
      <div class="pageMenu">
        <PageMenu />
      </div>
      <div class="pageHeader">
        <PageHeader>
          <div class="pageContent">
            <slot name="main"></slot>
          </div>
        </PageHeader>
      </div>
    </div>
  </div>
</template>
<script>
import PageAside from "@/components/pageNav/com/pageAside.vue";
import PageHeader from "@/components/pageNav/com/pageHeader.vue";
import PageMenu from "@/components/pageNav/com/pageMenu.vue";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    PageAside,
    PageHeader,
    PageMenu,
  },
  computed: {
    ...mapState(["pageRouter"]),
  },
  mounted() {},
  methods: {
    ...mapMutations(["setPageRouter"]),
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-dropdown-menu__item {
  border-top: 1px solid #ccc;
}
/deep/ .last_box {
  border-bottom: 1px solid #ccc;
}
.box-wrap {
  display: flex;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #f8f8f8;
  .box-left {
    width: 200px;
    height: 100%;
    box-sizing: border-box;
  }
  .box-right {
    width: 0;
    flex: 1;
    box-sizing: border-box;
    height: 100%;
    .pageMenu {
      height: 40px;
    }
    .pageContent {
      height: calc(100vh - 82px);
      overflow-y: auto;
    }
  }
}
</style>