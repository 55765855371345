import request from '@/router/axios'

// 分页查询活动列表
export function getChannelDataListApi(query) {
  return request({
    url: '/admin/channelData/page',
    method: 'get',
    params: query
  })
}

