import request from '@/router/axios'

// 获取渠道列表
export function getChannelListApi(query) {
  return request({
    url: '/admin/channel/page',
    method: 'get',
    params: query
  })
}

// 添加或修改渠道
export function addOrUpdateApi(obj) {
  return request({
    url: '/admin/channel/addOrUpdate',
    method: 'post',
    data: obj
  })
}