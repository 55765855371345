<template>
  <div>
    <el-table
      :data="tableData"
      :style="{ width, height }"
      :border="border"
      :highlight-current-row="highlightCurrentRow"
      :stripe="stripe"
    >
      <el-table-column v-if="showIndex" type="index"></el-table-column>
      <template v-for="(item, index) of tableColumn">
        <el-table-column
          v-if="item.slot"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width || 'auto'"
          :align="align"
        >
          <template slot-scope="scope">
            <slot
              :name="item.prop + 'Slot'"
              :row="scope.row"
            ></slot>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width || 'auto'"
          :align="align"
        >
          <template slot-scope="scope">
            {{ scope.row[item.prop] }}
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "ZHVTABLE",
  props: {
    tableColumn: {
      type: Array,
    }, // 表头数据
    tableData: {
      type: Array,
    }, // 表格数据
    height: {
      type: [String, Number],
      default: "-",
    }, // 高度,默认自适应
    width: {
      type: [String, Number],
      default: "-",
    },
    stripe: {
      type: Boolean,
      default: false,
    }, // 是否为斑马纹 table
    border: {
      type: Boolean,
      default: false,
    }, // 是否带有纵向边框
    highlightCurrentRow: {
      type: Boolean,
      default: false,
    }, // 是否要高亮当前行
    showIndex: {
      type: Boolean,
      default: false,
    }, // 是否显示index
    align: {
      type: String,
      default: "center",
    },
  },
  data() {
    return {
      test: {
        obj: '对象',
        name: '测试啊'
      }
    }
  }
};
</script>
<style lang="scss" scoped>
</style>