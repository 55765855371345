import request from '@/router/axios'

// 获取渠道列表
export function getFormListApi(query) {
  return request({
    url: '/admin/customerForm/page',
    method: 'get',
    params: query
  })
}

