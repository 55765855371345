<template>
  <div>
    <el-dialog
      title="新增跟进记录"
      :visible.sync="showModal"
      width="60%"
      center
      append-to-body
      @close="closeFun"
    >
      <div class="contentBox">
        <div class="headerItem">
          <p>编辑跟进记录：</p>
          <span>最多300字</span>
        </div>
        <el-form :model="form" :rules="rules" class="formCss" ref="formRef">
          <el-form-item label="" prop="content">
            <el-input
              type="textarea"
              :rows="10"
              placeholder="请输入跟进记录..."
              maxlength="300"
              resize="none"
              v-model="form.content"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showModal = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  addOrUpdateFormApi,
  queryFormByIdApi,
} from "@/api/clientele/clientele.js";
import { mapGetters } from "vuex";
export default {
  name: "lastRecordCom",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showModal: false,
      form: {
        content: "",
      },
      rules: {
        content: [
          { required: true, message: "请输入跟进记录", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    show(val) {
      this.showModal = val;
      if (val) {
        this.initFun();
      } else {
        this.closeFun();
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
  },
  methods: {
    initFun() {},
    closeFun() {
      this.showModal = false;
      this.$emit("update:show", false);
      this.form.content = ''
    },
    submit() {
      this.$refs["formRef"].validate((valid) => {
        if (valid) {
          this.$message.success("新增记录成功");
          this.$emit("emitSuccess", {
            lastRecord: this.form.content,
            recordItem: {
              adminName: this.userInfo.username,
              content:  this.form.content,
              createTime: this.timestampToTime(+new Date())
            },
          });
          this.closeFun();
        } else {
          return false;
        }
      });
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>
<style lang="scss" scoped>
.contentBox {
  padding: 0 100px;
}
.headerItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 16px;
    color: #000;
  }
  span {
    font-size: 13px;
    color: #666;
  }
}
.formCss /deep/ {
  padding-top: 20px;
  .el-textarea__inner {
    background-color: rgba(242, 242, 242, 1);
  }
}
</style>