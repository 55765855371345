var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('Basic-Container',[_c('avue-crud',{ref:"crud",staticClass:"avueCrud",attrs:{"page":_vm.page,"data":_vm.tableData,"table-loading":_vm.tableLoading,"option":_vm.tableOption},on:{"update:page":function($event){_vm.page=$event},"row-save":_vm.rowSave,"row-update":_vm.rowUpdate,"search-change":_vm.searchChange,"size-change":_vm.sizeChange,"current-change":_vm.currentChange,"refresh-change":_vm.refreshChange},scopedSlots:_vm._u([{key:"menuLeft",fn:function(ref){return [_c('el-tabs',{attrs:{"type":"border-card"},on:{"tab-click":_vm.tabClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"全部","name":""}}),_c('el-tab-pane',{attrs:{"label":"PC端","name":"pc"}}),_c('el-tab-pane',{attrs:{"label":"移动端","name":"mobile"}})],1)]}},{key:"date",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.date))])]}},{key:"channelId",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.channelName))])]}},{key:"sourceType",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.sourceType == "pc" ? "PC端" : row.sourceType == "mobile" ? "移动端" : "--")+" ")])]}},{key:"subChannelCode1",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.subChannelCode))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }