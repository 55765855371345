<template>
  <Basic-Container>
    <avue-crud class="avueCrud" ref="crud" :page.sync="page" :data="tableData" @row-save="rowSave" @row-update="rowUpdate"
      @search-change="searchChange" @size-change="sizeChange" @current-change="currentChange"
      @refresh-change="refreshChange" :table-loading="tableLoading" :option="tableOption">
      <template v-slot:createTime="{ row }">
        <div>{{ row.createTime }}</div>
      </template>
      <template v-slot:subUrl="{ row }">
        <span @click="activityUrlClick(row.subUrl)">{{ row.subUrl }}</span>
      </template>
    </avue-crud>
  </Basic-Container>
</template>
<script>
import {
  addOrUpdateApi,
  getSubChannelListApi,
  getChannelApi,
} from "@/api/channel/subchannel.js";
// import { tableOption } from "./tableOption.js";
const env = process.env.VUE_APP_ENV;
export default {
  name: "subChannel",
  components: {},
  filters: {},
  data() {
    return {
      searchForm: {},
      tableLoading: false,
      tableData: [],
      env: env,
      tableOption: {
        border: true,
        index: true,
        indexLabel: "序号",
        stripe: true,
        menuAlign: "center",
        align: "center",
        searchMenuSpan: 6,
        highlightCurrentRow: true,
        menuWidth: 300,
        viewBtn: false,
        delBtn: false,
        addBtn: true,
        tip: false,
        columnBtn: false,
        menu: true,
        searchLabelWidth: 100,
        labelWidth: 100,
        addBtnText: "新增子渠道",
        refreshBtn: true,
        column: [
          {
            type: "datetimerange",
            label: "子渠道创建日期",
            prop: "createTime",
            addDisplay: false,
            search: true,
            editDisplay: false,
            searchSpan: 6,
            searchRange: true,
            searchClearable: false,
            defaultTime: ["00:00:00", "23:59:59"],
            format: "yyyy-MM-dd HH:mm:ss", // 这是组件展示的日期格式
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            slot: true,
          },
          {
            type: "select",
            label: "渠道名称",
            prop: "parentChannelId",
            search: true,
            span: 13,
            dicUrl: "/admin/channel/list",
            props: {
              label: "channelName",
              value: "id",
            },
            editDisabled: true,
            rules: [
              {
                required: true,
                message: "请选择渠道名称",
                trigger: "blur",
              },
            ],
          },
          {
            type: "select",
            label: "渠道类型",
            prop: "parentChannelType",
            editDisabled: true,
            search: true,
            dicData: [
              {
                label: "搜索",
                value: 1,
              },
              {
                label: "信息流",
                value: 2,
              },
              {
                label: "公众号",
                value: 3,
              },
              {
                label: "短视频",
                value: 4,
              },
              {
                label: "自媒体",
                value: 5,
              },
              {
                label: "互动",
                value: 6,
              },
              {
                label: "线下",
                value: 7,
              },
              {
                label: "内容运营",
                value: 8,
              },
              {
                label: "支付",
                value: 9,
              },
              {
                label: '社群',
                value: 10
              },
              {
                label: "其他",
                value: 999,
              },
            ],
            rules: [
              {
                required: true,
                message: "请选择渠道类型",
                trigger: "blur",
              },
            ],
            span: 13,
            addDisplay: false,
            editDisabled: true,
          },

          {
            type: "input",
            label: "子渠道名称",
            prop: "channelName",
            search: true,
            span: 13,
            editDisabled: true,
            rules: [
              {
                required: true,
                message: "请输入子渠道名称",
                trigger: "blur",
              },
            ],
          },
          {
            type: "input",
            label: "子渠道编码",
            prop: "channelCode",
            editDisabled: true,
            search: true,
            maxlength: 18,
            rules: [
              {
                required: true,
                message: "请输入子渠道编码",
                trigger: "blur",
              },
            ],
            span: 13,
          },
          {
            type: "select",
            label: "结算方式",
            prop: "settlementType",
            span: 13,
            dicData: [
              {
                label: "CPC",
                value: 1,
              },
              {
                label: "CPA",
                value: 2,
              },
              {
                label: "CPS",
                value: 3,
              },
              {
                label: "CPM",
                value: 4,
              },
              {
                label: "CPT",
                value: 5,
              },
              {
                label: "其他",
                value: 6,
              },
            ],
            rules: [
              {
                required: true,
                message: "请选择结算方式",
                trigger: "blur",
              },
            ],
          },
          {
            type: "input",
            label: "结算单价",
            prop: "settlementPrice",
            span: 13,
            rules: [
              {
                required: true,
                message: "请输入结算单价",
                trigger: "blur",
              },
            ],
          },
          {
            type: "select",
            label: "投放活动",
            prop: "activityId",
            hide: true,
            search: true,
            editDisabled: true,
            span: 13,
            dicUrl: "/admin/activity/list",
            props: {
              label: "activityName",
              value: "id",
            },
          },
          {
            type: "select",
            label: "投放活动",
            prop: "activityName",
            addDisplay: false,
            editDisplay: false,
          },
          {
            type: "select",
            label: "渠道创建人",
            prop: "createBy",
            addDisplay: false,
            editDisplay: false,
          },
          {
            type: "select",
            label: "子渠道状态",
            prop: "status",
            search: true,
            dicData: [
              {
                label: "已禁用",
                value: 1,
              },
              {
                label: "已启用",
                value: 0,
              },
            ],
            rules: [
              {
                required: true,
                message: "请选择账号状态",
                trigger: "blur",
              },
            ],
          },
          {
            type: "select",
            label: "渠道创建人",
            prop: "createId",
            addDisplay: false,
            editDisplay: false,
            hide: true,
            search: true,
            dicUrl: "/admin/list",
            props: {
              label: "username",
              value: "id",
            },
          },
          {
            type: "textarea",
            label: "子渠道备注信息",
            maxlength: 100,
            prop: "remarks",
            span: 13,
          },
          {
            type: "textarea",
            label: "子渠道投放链接",
            maxlength: 100,
            prop: "subUrl",
            addDisplay: false,
            editDisplay: false,
            slot: true,
          },
        ],
      },
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50, 100],
      },
      selectData: {},
    };
  },
  created() {
    this.getList(this.page);
  },
  computed: {},
  methods: {
    getList(page, params) {
      this.tableLoading = true;
      getSubChannelListApi(
        Object.assign(
          {
            current: page.currentPage,
            size: page.pageSize,
          },
          params,
          this.searchForm
        )
      )
        .then(async (response) => {
          let data = response.data.data;
          if (data.records && data.records.length > 0) {
            for (let i = 0; i < data.records.length; i++) {
              const item = data.records[i];
              item.settlementPrice = item.settlementPrice / 100;
              await this.setSubUrl(item);
            }
          }
          this.tableData = data.records || [];
          this.page.total = Number(data.total) || 0;
          this.tableLoading = false;
        })
        .catch(() => {
          this.$message.error("查询失败");
          this.tableLoading = false;
        });
    },
    searchChange(form, done) {
      if (form.createTime) {
        form.createStartTime = form.createTime[0];
        form.createEndTime = form.createTime[1];
        delete form.createTime;
      }
      this.searchForm = form;
      this.page.currentPage = 1;
      this.getList(this.page, form);
      done();
    },
    sizeChange(pageSize) {
      this.page.currentPage = 1;
      this.page.pageSize = pageSize;
      this.getList(this.page);
    },
    currentChange(current) {
      this.page.currentPage = current;
      this.getList(this.page);
    },
    searchResetFn() { },
    rowSave(form, done, loading) {
      loading();
      let params = JSON.parse(JSON.stringify(form));
      params.settlementPrice = params.settlementPrice * 100;
      addOrUpdateApi(params)
        .then((res) => {
          this.getList(this.page);
          this.$message.success("新增成功");
          done();
        })
        .catch((err) => {
          this.$message.error("新增失败");
        });
    },
    rowUpdate(form, index, done, loading) {
      let params = JSON.parse(JSON.stringify(form));
      params.settlementPrice = params.settlementPrice * 100;
      addOrUpdateApi(params)
        .then((res) => {
          this.$message.success("修改成功");
          this.getList(this.page);
          done();
        })
        .catch((err) => {
          loading();
          this.$message.warning("网络错误,请稍候重试");
        });
    },
    refreshChange() {
      this.getList(this.page);
    },
    setSubUrl(row) {
      return new Promise(async (resolve) => {
        let str = "";
        let url = "";
        console.log(row);
        let href = window.location.href
        if (href.includes('https://test-admin.zhxweilai.com/')) {
          url = "https://test-h5.zhxweilai.com/#/tfhd/jiugongshan/home/";
        }else {
          url = "https://www.zhxweilai.com/#/tfhd/jiugongshan/home/";
        }
        let parentChanneCode = await this.getChannelFun(row.parentChannelId);
        str = url + parentChanneCode + "/" + row.channelCode;
        row.subUrl = str;
        resolve();
      });
    },
    activityUrlClick(url) {
      this.$Clipboard({
        text: url,
      })
        .then(() => {
          this.$confirm("链接已经复制,是否在新标签页打开此链接?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            window.open(url);
          });
        })
        .catch(() => {
          this.$message.error("复制失败");
        });
    },
    getChannelFun(channelId) {
      return new Promise((resolve) => {
        getChannelApi({ channelId }).then((res) => {
          resolve(res.data.data[0].channelCode);
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>