<template>
  <div class="login-box">
    <div class="login-item">
      <div class="logo-box">
        <img src="@/assets/img/logo.jpeg" alt="" />
      </div>
      <div class="heder-box">众兴未来管理系统</div>
      <div class="form-box">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item
            class="form-item-css"
            status-icon
            label="账号"
            prop="username"
          >
            <el-input
              type="text"
              v-model="ruleForm.username"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item class="form-item-css" label="密码" prop="pass">
            <el-input
              type="password"
              v-model="ruleForm.password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item class="">
            <el-button
              type="success"
              class="loginBtn"
              @click="submitForm('ruleForm')"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { postApiLogin, getUserInfoApi } from "@/api/user/login.js";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Login",
  components: {},
  data() {
    var validateUsername = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        if (value.length < 1) {
          callback(new Error("账号最少为1位"));
        }
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (value.length < 6) {
          callback(new Error("密码最少为6位"));
        }
        callback();
      }
    };
    return {
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ validator: validateUsername, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
      },
      toPath: "",
    };
  },
  created() {
    this.toPath = this.$route.query.to || "/";
  },
  computed: {
    ...mapGetters(["userAccountList"]),
  },
  methods: {
    ...mapActions(["newTokenInfo", "newUserInfo"]),
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          postApiLogin(this.ruleForm)
            .then((res) => {
              let data = res.data.data || false;
              if (data) {
                this.newTokenInfo(res.data.data).then((res) => {
                  getUserInfoApi().then((res) => {
                    let data = res.data.data || false;
                    console.log(data,'data');
                    if (data) {
                      this.newUserInfo(data).then((res) => {
                        this.$message.success("登录成功");
                        setTimeout(() => {
                          this.$router.push({
                            path: "/",
                          });
                        }, 100);
                      });
                    }
                  });
                });
              }else {
                this.$message.error(res.data.message || '登录失败, 请稍候再试')
              }
            })
            .catch((err) => {
              console.log(err, "错误");
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.login-box {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/%E5%90%8E%E5%8F%B0%E8%83%8C%E6%99%AF%E5%9B%BE1_412671959944609810.jpg")
    no-repeat center;
  background-size: cover;
  color: #fff;
  .login-item {
    width: 30%;
    .logo-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
      width: 200px;
      background: #fff;
      margin: 0 auto;
      border-radius: 50%;
      margin-bottom: 30px;
      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
      }
    }
    .heder-box {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      color: #333;
    }
    .form-box {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 50px;
      box-sizing: border-box;
      padding-right: 40px;
      .form-item-css /deep/ {
        width: 600px;
        .el-form-item__label {
          color: #777;
        }
      }
      .loginBtn {
        width: 100%;
      }
    }
  }
}
</style>