
export const tableOption = {
  border: true,
  index: true,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  align: 'center',
  searchMenuSpan: 6,
  highlightCurrentRow: true,
  menuWidth: 300,
  viewBtn: false,
  // editBtn: false,
  delBtn: true,
  addBtn: true,
  refreshBtn: true,
  tip: false,
  // header: false,
  columnBtn: false,
  menu: true,
  searchLabelWidth: 100,
  addBtnText: '新增角色',
  column: [
    {
      type: 'input',
      label: 'ID',
      prop: 'id',
      addDisplay: false,
      editDisplay: false,
    },
    {
      type: 'input',
      label: '角色名称',
      prop: 'name',
      rules: [{
        required: true,
        message: "请输入角色名称",
        trigger: "blur"
      }]
    },
    {
      type: 'select',
      label: '角色标识',
      prop: 'roleCode',
      dicData: [
        {
          label: 'super',
          value: 'super'
        },
        {
          label: 'sale',
          value: 'sale'
        },
        {
          label: 'service',
          value: 'service'
        },
      ]
    },
    {
      type: 'input',
      label: '角色描述',
      prop: 'roleDesc',
    },
    {
      type: 'input',
      label: '创建时间',
      prop: 'createTime',
      addDisplay: false,
      editDisplay: false
    },
    
    // {
    //   type: 'input',
    //   label: '页面属性',
    //   prop: 'type',
    //   slot: true
    // },
  ]
}
