import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
// 自定义代码高亮
import Highlight from './util/highlight';
// vue代码高亮
import VueHighlightJS from 'vue-highlightjs'
// 代码高亮暗系css
import 'highlight.js/styles/atom-one-dark.css'
// import 'highlight.js/styles/default.css' // or other highlight.js theme
import 'element-ui/lib/theme-chalk/index.css';
// iconFont
import "@/assets/iconFont/iconfont.css"
// 基础容器组件
import basicContainer from "@/components/basicContainer/index.vue"
// 表格组件
import zhvTable from '@/components/zhvTable/index.vue'
// 文件列表组件
import FileList from '@/components/fileUpList/index.vue'
// 导航组件
import layout from "@/components/layout/index.vue";
// 图片预览
import Viewer from 'v-viewer' // 引入js
import 'viewerjs/dist/viewer.css' // 引入css
Vue.use(Viewer) // 默认配置写法
// Avue
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
import axios from './router/axios'
Vue.use(Avue, { axios })
// Vue.use(Avue);
Vue.component('BasicContainer', basicContainer)
Vue.component('ZhvTable', zhvTable)
Vue.component('Layout', layout)
Vue.component('FileList', FileList)

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Highlight)
Vue.use(VueHighlightJS)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')