<template>
  <div>
    <div v-for="(list, index) of menu" :key="index">
      <el-submenu
        v-if="list.children && list.children.length > 0"
        :index="index + ''"
      >
        <template slot="title">
          <i v-if="list.meta && list.meta.icon" :class="[list.meta.icon]"></i>
          <span>{{ list.meta.title }}</span>
        </template>
        <div v-for="(item, itemIndex) of list.children" :key="itemIndex">
          <template v-if="item.children && item.children.length > 0">
            <Zhvmenu :menu="[item]" />
          </template>
          <el-menu-item
            v-else
            :class="{ isActive: activePage.path === list.path + item.path }"
            :index="list.path + item.path"
          >
            <div class="son-item menu-item-css">
              <i
                v-if="item.meta && item.meta.icon"
                :class="[item.meta.icon ? item.meta.icon : '']"
              ></i>
              <span>{{ item.meta.title }}</span>
            </div>
          </el-menu-item>
        </div>
      </el-submenu>
      <template v-else>
        <el-menu-item :index="list.path">
          <div class="menu-item-css">
            <i
              v-if="list.meta && list.meta.icon"
              :class="[list.meta.icon ? list.meta.icon : '']"
            ></i>
            <span>{{ list.meta.title }}</span>
          </div>
        </el-menu-item>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Zhvmenu",
  props: {
    menu: {
      type: Array,
    },
  },
  data() {
    return {
      navList: {},
    };
  },
  computed: {
    ...mapGetters(["activePage", "pageRouter"]),
    asideList() {
      for (const item of this.pageRouter) {
        if (item.children && item.children.length > 0) {
          item.hasChildren = true;
        }
      }
      return this.pageRouter;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.menu-item-css {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}
.menu-item-css i {
  transform: translateY(-2px);
}
.son-item.menu-item-css {
  padding-left: 30px;
}
</style>