<template>
  <Basic-Container>
    <avue-crud
      class="avueCrud"
      ref="crud"
      :page.sync="page"
      :data="tableData"
      :table-loading="tableLoading"
      :option="tableOption"
      @row-save="rowSave"
      @row-del="rowDel"
      @row-update="rowUpdate"
      @refresh-change="refreshChange"
    >
    </avue-crud>
  </Basic-Container>
</template>
<script>
import {
  getRoleListApi,
  postAddRoleApi,
  deleteRoleApi,
  postUpdateRoleApi,
} from "@/api/user/role.js";
import { tableOption } from "@/views/user/role/tableOption.js";
export default {
  name: "userManage",
  components: {},
  data() {
    return {
      searchForm: {},
      tableLoading: false,
      tableData: [],
      tableOption: tableOption,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50, 100],
      },
      selectData: {},
    };
  },
  created() {
    this.getList(this.page);
  },
  computed: {},
  methods: {
    getList(page, params) {
      this.tableLoading = true;
      getRoleListApi(
        Object.assign(
          {
            current: page.currentPage,
            size: page.pageSize,
          },
          params,
          this.searchForm
        )
      )
        .then((res) => {
          let data = res.data.data || {};
          this.tableData = data.records || [];
          this.page.total = Number(data.total) || 0;
          this.tableLoading = false;
          if (this.tableData.length < 1) {
            this.$message.warning("暂无数据");
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    rowSave(form, done, loading) {
      loading();
      postAddRoleApi(form)
        .then((res) => {
          console.log(res.data, "ddd");
          this.getList(this.page);
          this.$message.success("新增成功");
          done();
        })
        .catch((err) => {});
    },
    // 刷新
    refreshChange() {
      this.getList(this.page);
    },
    // 删除
    rowDel(form, index) {
      this.$confirm("是否删除当前角色?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteRoleApi(form.id)
            .then((res) => {
              this.$message.success("删除成功");
              this.getList(this.page);
            })
            .catch((err) => {
              console.log(err, "错误");
              this.$message.warning("网络错误,请稍候重试");
            });
        })
        .catch(() => {});
    },
    rowUpdate(form, index, done, loading) {
      postUpdateRoleApi(form)
        .then((res) => {
          this.$message.success("修改成功");
          this.getList(this.page);

          done();
        })
        .catch((err) => {
          loading();
          this.$message.warning("网络错误,请稍候重试");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>