<template>
  <div class="menuBox">
    <el-dropdown @command="handleCommand">
      <div class="user_box">
        <!-- <img class="user_img" :src="userInfo.avatar" alt="" /> -->
        <div class="user_name">
          <span>{{ userInfo.username }}</span
          ><i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="home">首页</el-dropdown-item>
          <!-- <el-dropdown-item command="info">个人信息</el-dropdown-item> -->
          <el-dropdown-item command="quit">退出系统</el-dropdown-item>
        </el-dropdown-menu>
      </div>
    </el-dropdown>
  </div>
</template>
<script>
import { postLogoutApi } from "@/api/user/login.js";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "pageMenu",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    ...mapMutations(["removeUserInfo"]),
    handleCommand(command) {
      switch (command) {
        case "home":
          this.goHome();
          break;
        case "quit":
          this.quitClick();
          break;
        case "info":
          this.goSetUp();
          break;
        default:
          this.$message.warning("暂不支持的功能,敬请期待!");
          break;
      }
    },
    goHome() {
      let path = this.$route.path;
      if (path !== "/") {
        this.$router.push({
          path: "/",
        });
      }
    },
    quitClick() {
      this.$confirm("此操作将退出系统登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          postLogoutApi()
            .then((res) => {
              this.removeUserInfo();
              this.$router.push({
                path: "/login",
              });
            })
            .catch((err) => {
              this.removeUserInfo();
              this.$router.push({
                path: "/login",
              });
            });
        })
        .catch(() => {});
    },
    goSetUp() {
      this.$router.push({
        path: "/user/setUpInfo",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.menuBox {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  .user_box {
    display: flex;
    align-items: center;
    padding-right: 10px;
    .user_img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
</style>