<template>
  <Layout>
    <Basic-Container
      backgroundImg="https://zxwl-marketing.oss-cn-beijing.aliyuncs.com/zxwl-marketing/%E5%90%8E%E5%8F%B0%E8%83%8C%E6%99%AF%E5%9B%BE2_412671960452120665.jpg"
    >
      <div class="home">
        <div class="home-title">众兴未来后台管理系统</div>
      </div>
    </Basic-Container>
  </Layout>
</template>

<script>
import Layout from "@/components/layout/index.vue";
export default {
  name: "Home",
  components: {
    Layout,
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.home {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* background: url("https://eshop1212-oss-prod.oss-cn-shenzhen.aliyuncs.com/test-eshop/656/4467ef3eae9f42d4afc334b7aa2c5fea.jpg") */
  /* background: url("https://eshop1212-oss-prod.oss-cn-shenzhen.aliyuncs.com/test-eshop/0/d8e9b0e3d2484933b41f9fcb0326e338.jpeg") */
  /* no-repeat center; */
  /* background: url("https://dp1212-product.oss-cn-shenzhen.aliyuncs.com/new-eshop/9/49f85f283949414fa5a4c50ee0957119.jpg") */
  /* background: url("https://dp1212-product.oss-cn-shenzhen.aliyuncs.com/new-eshop/0/8b3ef6150c2d4cb1bfe29ed4d5e02e32.jpg") */
  /* background: url("https://eshop1212-oss-prod.oss-cn-shenzhen.aliyuncs.com/test-eshop/655/5a24ff766df84690b959b45fd16fe989.jpg")
    no-repeat center; */
  background-size: cover;
  .home-title {
    font-size: 40px;
    color: aliceblue;
    font-weight: bold;
  }
}
</style>
