import request from '@/router/axios'

// 获取渠道列表
export function getClientListApi(query) {
  return request({
    url: '/admin/client/page',
    method: 'get',
    params: query
  })
}

// 添加或修改渠道
export function addOrUpdateApi(obj) {
  return request({
    url: '/admin/channel/addOrUpdate',
    method: 'post',
    data: obj
  })
}
export function addOrUpdateFormApi(obj) {
  return request({
    url: '/admin/customerForm/addOrUpdate',
    method: 'post',
    data: obj
  })
}
// 根据id查询记录
export function queryFormByIdApi(query) {
  return request({
    url: '/admin/customerForm/queryById',
    method: 'get',
    params: query
  })
}

export function distributionAdminApi(obj) {
  return request({
    url: '/admin/customerForm/distributionAdmin',
    method: 'post',
    params: obj
  })
}
