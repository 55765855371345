<template>
  <div class="box">
    <el-tabs
      v-model="curentListObj.path"
      :closable="pageList.length > 1"
      @tab-remove="removeTab"
      @tab-click="tabClick"
      type="border-card"
    >
      <el-tab-pane
        v-for="item in pagePageList"
        :key="item.path"
        :label="item.meta.title"
        :name="item.path"
      >
        <!-- 解决重复加载问题 -->
        <div v-if="curentListObj.path === item.path">
          <slot></slot>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["pageList", "activePage"]),
    curentListObj() {
      let obj = Object.assign({}, this.activePage);
      return obj;
    },
    pagePageList() {
      return this.pageList;
    },
  },
  data() {
    return {
      list: [],
      curentList: [],
    };
  },
  mounted() {},
  methods: {
    // 移除
    removeTab(targetName) {
      if (this.pagePageList.length > 1) {
        let targetIndex = this.pagePageList.findIndex((item) => {
          return item.path === targetName;
        });
        let pageLength = this.pagePageList.length;
        let nowIndex = "";
        let deletPage = this.pagePageList.splice(targetIndex, 1);
        if (targetIndex != 0 && targetIndex != pageLength - 1) {
          nowIndex = targetIndex + 1;
        } else {
          nowIndex = targetIndex - 1;
        }
        if (nowIndex < 0) {
          nowIndex = 0;
        }
        if (this.activePage.path != this.pagePageList[nowIndex].path) {
          this.$router.push({
            path: this.pagePageList[nowIndex].path,
            query: this.pagePageList[nowIndex].query,
          });
        }
        this.$store
          .dispatch("newPageList", this.pagePageList)
          .then((res) => {});
      }
    },
    // 点击
    tabClick(data) {
      let page = this.findPage(data.name);
      if (this.activePage.path != page.path) {
        this.$router.push({ path: page.path, query: page.query });
      }
    },
    findPage(name) {
      let page = this.pagePageList.find((item) => {
        if (item.path === name) {
          return item;
        }
      });
      return page;
    },
  },
};
</script>
<style lang="scss" scoped>
.box /deep/ {
  box-sizing: border-box;
  .el-tabs__content {
    padding: 0;
  }
}
</style>