<template>
  <div class="fileBox">
    <div class="headerItem">
      <div class="titleText">素材管理</div>
      <el-upload
        class="upload-demo"
        action="''"
        multiple
        :limit="10"
        :show-file-list="false"
        :http-request="uploadtRequest"
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
    </div>
    <el-card class="contentItem">
      <div class="contentList" :class="{ choseCss: type == 'choose' }">
        <el-card
          class="imgList"
          :class="{ videoList: item.fileType === 2 }"
          v-for="(item, index) of fileList"
          :key="index"
        >
          <div class="indexLabel">
            {{
              pages.current == 1
                ? pages.current * (index + 1)
                : (pages.current - 1) * pages.size + (index + 1)
            }}
          </div>
          <div class="icon" @click="chooseItem(item)" v-if="type == 'choose'">
            <i
              v-if="item.isSelect"
              class="iconfont icon-yuanxingxuanzhongfill"
            ></i>
            <i
              v-else-if="noChose"
              class="disabledCss iconfont icon-jinzhixuanze"
            ></i>
            <i v-else class="iconfont icon-yuanxingweixuanzhong"></i>
          </div>
          <img
            v-if="item.fileType === 1"
            class="imgcss"
            :src="item.url"
            alt=""
          />
          <video
            v-else
            width="100%"
            :height="150"
            controls="controls"
            preload="auto"
          
          >
            <source :data-src="item.url" :src="item.url" type="video/mp4" />
          </video>
          <p class="imgName">{{ item.fileName }}</p>
        </el-card>
      </div>
      <div class="footerItem">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.current"
          :page-sizes="pages.pageSizes"
          :page-size="pages.size"
          :total="pages.total"
          layout="total, prev, pager, next,sizes, jumper"
        >
        </el-pagination>
      </div>
    </el-card>
    <div class="dialog-footer">
      <div class="tips">当前已选择{{ chooseList.length }}项</div>
      <el-button type="primary" @click="chooseClick">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { uploadtApi, getOssPageList } from "@/api/filePage/filePage.js";
export default {
  name: "fileUpList",
  components: {},
  props: {
    type: {
      type: String,
      default: "pege",
    },
    fileType: {
      type: String,
      default: "image",
    },
    num: {
      type: Number,
      default: 1,
    },
    // 素材数据
    upfileList: { type: Array, default: () => [] },
  },
  data() {
    return {
      fileList: [],
      pages: {
        pageSizes: [50, 100, 150, 200],
        size: 20,
        current: 1,
        total: 0,
      },
      chooseList: [],
    };
  },
  watch: {
    upfileList: function (val) {
      this.chooseList = JSON.parse(JSON.stringify(val));
      this.setChooseStatus();
    },
  },
  created() {
    this.getList();
  },
  computed: {
    noChose() {
      return this.chooseList.length >= this.num;
    },
  },
  methods: {
    getList() {
      let pages = this.pages;
      let params = {
        size: pages.size,
        current: pages.current,
      };
      if (this.type !== "pege") {
        params.fileType = this.fileType === "video" ? 2 : 1;
      }
      getOssPageList(params).then((res) => {
        let data = res.data?.data?.records;
        let total = res.data?.data?.total;
        this.pages.total = Number(total) || 0;
        this.fileList = data || [];
        this.setChooseStatus();
      });
    },
    uploadtRequest(e) {
      let file = e.file;
      let fd = new FormData();
      fd.append("file", file);
      uploadtApi(fd).then((res) => {
        if (res.data.code == "200") {
          this.$message.success("上传成功");
          this.getList();
        }
      });
    },
    handleSizeChange(size) {
      console.log(size);
      this.pages.size = size;
      this.getList();
    },
    handleCurrentChange(current) {
      this.pages.current = current;
      this.getList();
      console.log(current, "current");
    },
    chooseItem(item) {
      if (this.type !== "choose") {
        return;
      }
      if (!item.isSelect && this.noChose) {
        return;
      }
      if (this.chooseList.includes(item.url)) {
        this.chooseList.splice(this.chooseList.indexOf(item.url), 1);
        item.isSelect = false;
      } else {
        this.chooseList.push(item.url);
        item.isSelect = true;
      }
    },
    setChooseStatus() {
      for (let i = 0; i < this.fileList.length; i++) {
        const item = this.fileList[i];
        if (this.chooseList.includes(item.url)) {
          item.isSelect = true;
        } else {
          item.isSelect = false;
        }
      }
    },
    chooseClick() {
      this.$emit("emit-choose", this.chooseList);
    },
  },
};
</script>
<style lang="scss" scoped>
.fileBox {
  width: 100%;
  display: flex;
  flex-direction: column;

  .headerItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding-bottom: 10px;
    box-sizing: border-box;
    .titleText {
      font-size: 24px;
      font-weight: bold;
      color: #606266;
    }
  }
  .contentItem /deep/ {
    flex: 1;
    .el-card__body {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
    .contentList {
      width: 100%;
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      &.choseCss {
        height: 60vh;
        flex: none;
      }
      // justify-content: space-between;
      .imgList {
        width: 18%;
        margin: 1%;
        height: 230px;
        position: relative;
        box-sizing: border-box;
        &.videoList {
          padding-top: 20px;
        }
        .indexLabel {
          position: absolute;
          top: 4px;
          left: 4px;
          display: flex;
          align-items: center;
          min-height: 30px;
          min-width: 30px;
          border-radius: 50%;
          justify-content: center;
          background: rgba(103, 194, 58, 0.6);
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          color: #409eff;
        }
        .icon {
          position: absolute;
          top: 4px;
          right: 4px;
          display: flex;
          align-items: center;
          font-size: 14px;

          .iconfont {
            font-size: 26px;
            color: #409eff;
          }
          .disabledCss {
            color: #c0c4cc;
          }
        }
        .imgcss {
          display: block;
          margin: 20px auto 0;
          width: 150px;
          height: 150px;
        }
        .imgName {
          font-size: 13px;
          padding-top: 20px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .imgList:nth-of-type(5n) {
        margin-right: 0;
      }
    }
    .footerItem {
      height: 5%;
      padding-top: 10px;
      box-sizing: border-box;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  .tips {
    font-size: 18px;
    padding-right: 30px;
    color: #0084ff;
  }
}
</style>