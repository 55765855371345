var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('Basic-Container',[_c('avue-crud',{ref:"crud",staticClass:"avueCrud",attrs:{"page":_vm.page,"data":_vm.tableData,"before-open":_vm.beforeOpen,"table-loading":_vm.tableLoading,"option":_vm.tableOption},on:{"update:page":function($event){_vm.page=$event},"row-save":_vm.rowSave,"search-change":_vm.searchChange,"size-change":_vm.sizeChange,"current-change":_vm.currentChange,"refresh-change":_vm.refreshChange},scopedSlots:_vm._u([{key:"createTime",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.createTime))])]}},{key:"distributionTime",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.distributionTime))])]}},{key:"ageRange",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.ageRange || "—"))])]}},{key:"question1",fn:function(ref){
var row = ref.row;
return [(row.question1 && row.question1.length > 0)?_c('div',{staticClass:"questionShow"},_vm._l((row.question1),function(tag,index){return _c('div',{key:index,staticClass:"questionTag"},[_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(tag.key))])],1)}),0):_c('div',[_vm._v("暂未填写")])]}},{key:"question2",fn:function(ref){
var row = ref.row;
return [(row.question2 && row.question2.length > 0)?_c('div',_vm._l((row.question2),function(tag,index){return _c('div',{key:index,staticClass:"questionTag"},[_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(tag.key))])],1)}),0):_c('div',[_vm._v("暂未填写")])]}},{key:"question3",fn:function(ref){
var row = ref.row;
return [(row.question3 && row.question3.length > 0)?_c('div',{staticClass:"questionShow"},_vm._l((row.question3),function(tag,index){return _c('div',{key:index,staticClass:"questionTag"},[_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(tag.key))])],1)}),0):_c('div',[_vm._v("暂未填写")])]}},{key:"question4",fn:function(ref){
var row = ref.row;
return [(row.question4 && row.question4.length > 0)?_c('div',_vm._l((row.question4),function(tag,index){return _c('div',{key:index,staticClass:"questionTag"},[_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(tag.key))])],1)}),0):_c('div',[_vm._v("暂未填写")])]}},{key:"clentTag",fn:function(ref){
var row = ref.row;
return [_c('div',{},[_vm._v(_vm._s(_vm._f("filterTag")(row)))])]}},{key:"channelId",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.channelName))])]}},{key:"subChannelCode",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.subChannelName))])]}},{key:"driveTime",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.needDrive == 2 ? "不需要专车" : row.driveTime ? row.driveTime : "暂未填写"))])]}},{key:"activityUrl",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.activityUrlClick(row.activityUrl)}}},[_vm._v(_vm._s(row.activityUrl))])]}},{key:"sourceType",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.sourceType == "pc" ? "PC端" : row.sourceType == "mobile" ? "移动端" : "--")+" ")])]}},{key:"menu",fn:function(ref){
var size = ref.size;
var row = ref.row;
return [_c('el-button',{attrs:{"plain":"","size":size,"type":"primary"},on:{"click":function($event){return _vm.followInfo(row)}}},[_vm._v("完善跟进信息")]),_c('el-button',{attrs:{"plain":"","size":size,"type":"danger"},on:{"click":function($event){return _vm.distributeClick(row)}}},[_vm._v("客户分配")])]}}]),model:{value:(_vm.crudModel),callback:function ($$v) {_vm.crudModel=$$v},expression:"crudModel"}}),_c('FollowModal',{attrs:{"editId":_vm.editId,"show":_vm.showFollowModal},on:{"update:show":function($event){_vm.showFollowModal=$event},"emit-add":_vm.emitAdd}}),_c('DistributeModal',{attrs:{"editId":_vm.editId,"editAdminId":_vm.adminId,"show":_vm.showDistributeModal},on:{"update:show":function($event){_vm.showDistributeModal=$event},"emit-submit":_vm.refreshChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }