
export const tableOption = {
  border: true,
  index: true,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  align: 'center',
  searchMenuSpan: 6,
  highlightCurrentRow: true,
  menuWidth: 300,
  viewBtn: false,
  delBtn: false,
  addBtn: true,
  tip: false,
  columnBtn: false,
  menu: true,
  searchLabelWidth: 100,
  labelWidth: 100,
  addBtnText: '新增渠道',
  refreshBtn: true,

  column: [
    {
      type: 'datetimerange',
      label: '渠道创建日期',
      prop: 'createTime',
      addDisplay: false,
      search: true,
      editDisplay: false,
      searchSpan: 6,
      searchRange: true,
      searchClearable: false,
      defaultTime: ['00:00:00', '23:59:59'],
      format: 'yyyy-MM-dd HH:mm:ss',                  // 这是组件展示的日期格式
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      slot: true
    },
    {
      type: 'input',
      label: '渠道名称',
      prop: 'channelName',
      search: true,
      maxlength: 18,
      rules: [{
        required: true,
        message: "请输入渠道名称",
        trigger: "blur"
      }],
      span: 13,
      editDisabled: true
    },
    {
      type: 'input',
      label: '渠道编码',
      prop: 'channelCode',
      search: true,
      maxlength: 18,
      rules: [{
        required: true,
        message: "请输入渠道编码",
        trigger: "blur"
      }],
      span: 13,
      editDisabled: true
    },
    {
      type: 'select',
      label: '渠道类型',
      prop: 'channelType',
      search: true,
      dicData: [
        {
          label: '搜索',
          value: 1
        },
        {
          label: '信息流',
          value: 2
        },
        {
          label: '公众号',
          value: 3
        },
        {
          label: '短视频',
          value: 4
        },
        {
          label: '自媒体',
          value: 5
        },
        {
          label: '互动',
          value: 6
        },
        {
          label: '线下',
          value: 7
        },
        {
          label: '内容运营',
          value: 8
        },
        {
          label: '支付',
          value: 9
        },
        {
          label: '社群',
          value: 10
        },
        {
          label: '其他',
          value: 999
        }
      ],
      rules: [{
        required: true,
        message: "请选择渠道类型",
        trigger: "blur"
      }],
      span: 13
    },
    {
      type: 'select',
      label: '渠道创建人',
      prop: 'createBy',
      addDisplay: false,
      editDisplay: false,
    },
    {
      type: 'select',
      label: '渠道创建人',
      prop: 'createId',
      addDisplay: false,
      editDisplay: false,
      hide: true,
      search: true,
      dicUrl: '/admin/list',
      props: {
        label: 'username',
        value: 'id'
      },
    },
    {
      type: 'textarea',
      label: '备注信息',
      prop: 'remarks',
      span: 13
    },
  ]
}
