import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import Stroe from '../store/index'
import layout from '@/components/layout/index.vue'
import NotFound from '@/views/404/index.vue'
import login from '@/views/login/index.vue'
import FilePage from '@/views/filePage/index.vue'
import userManage from '@/views/user/manage/index.vue'
import Role from '@/views/user/role/index.vue'
import Channel from '@/views/channel/channel/index.vue'
import subChannel from '@/views/channel/subChannel/index.vue'
import Clientele from '@/views/clientele/index.vue'
import Activity from '@/views/activity/index.vue'
import ChannelData from '@/views/channelData/index.vue'
import FormAdmin from '@/views/formAdmin/index.vue'
Vue.use(VueRouter)
const routes = [{
  path: '/',
  name: 'Home',
  component: Home,
  meta: {
    title: '首页',
    icon: 'el-icon-s-home',
  }
},
{
  path: '/user/',
  name: 'user',
  component: layout,
  meta: {
    title: '用户管理',
    icon: 'el-icon-user',
  },
  children: [
    {
      path: 'manage',
      name: 'userManage',
      component: userManage,
      meta: {
        title: '账号管理',
        icon: 'el-icon-user-solid',
      }
    },
    {
      path: 'role',
      name: 'role',
      component: Role,
      meta: {
        title: '角色管理',
        icon: 'el-icon-s-tools',
      }
    },

  ]
},
{
  path: '/formadmin',
  name: 'FormAdmin',
  component: FormAdmin,
  meta: {
    title: '表单管理',
    icon: 'el-icon-s-order',
  }
},
{
  path: '/clientele',
  name: 'Clientele',
  component: Clientele,
  meta: {
    title: '客户管理',
    icon: 'el-icon-user-solid',
  }
},
{
  path: '/activity',
  name: 'Activity',
  component: Activity,
  meta: {
    title: '活动管理',
    icon: 'el-icon-s-promotion',
  }
},
{
  path: '/filePage',
  name: 'FilePage',
  component: FilePage,
  meta: {
    title: '素材管理',
    icon: 'el-icon-folder-opened',
  }
},

{
  path: '/login/',
  name: 'login',
  component: login,
  meta: {
    title: '欢迎登录',
    isNotTab: true
  }
},

{
  path: '/channel/',
  name: 'channelP',
  component: layout,
  meta: {
    title: '渠道管理',
    icon: 'el-icon-s-platform',
  },
  children: [
    {
      path: 'channel',
      name: 'channel',
      component: Channel,
      meta: {
        title: '渠道管理',
        icon: 'el-icon-s-cooperation',
      }
    },
    {
      path: 'subchannel',
      name: 'subchannel',
      component: subChannel,
      meta: {
        title: '子渠道管理',
        icon: 'el-icon-s-order',
      }
    },

  ]
},
{
  path: '/channeldata',
  name: 'ChannelData',
  component: ChannelData,
  meta: {
    title: '渠道数据',
    icon: 'el-icon-s-data',
  }
},
{
  path: '/xmwmanage/',
  name: 'xmwManage',
  component: layout,
  meta: {
    title: '选墓网管理',
    icon: 'el-icon-s-platform',
  },
  children: [
    {
      path: 'cemetery',
      name: 'cemetery',
      component: ()=> import("@/views/xmwmanage/cemetery/index.vue"),
      meta: {
        title: '陵园管理',
        icon: 'el-icon-receiving',
      }
    },
    {
      path: 'recommended',
      name: 'Recommended',
      component: ()=> import("@/views/xmwmanage/recommended/index.vue"),
      meta: {
        title: '首页推荐管理',
        icon: 'el-icon-collection',
      }
    },
    {
      path: 'info',
      name: 'Info',
      component: ()=> import("@/views/xmwmanage/info/index.vue"),
      meta: {
        title: '资讯管理',
        icon: 'el-icon-document-copy',
      }
    },
  ]
},
{
  path: '*',
  name: 'NotFound',
  component: NotFound,
  meta: {
    title: '404',
    isNotTab: true
  }
}
]
Stroe.dispatch('newPageRouter', routes)
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (Stroe.getters.token) {
    next()
  } else {
    if (to.path == '/login') {
      next()
    } else {
      document.title = '欢迎登录'
      next({
        replace: true,
        path: `/login?to=${to.path}`
      })
    }
  }

})
router.afterEach((to, next) => {
  let pageList = Stroe.getters.pageList || []
  let obj = {
    meta: to.meta || {
      icon: '',
      title: ''
    },
    name: to.name,
    path: to.path,
    query: to.query,
    params: to.params,
    fullPath: to.fullPath
  }
  if (obj.name === "NotFound" || obj.name === 'login') {
    return
  }
  pageList.push(obj)
  // 数组去重
  for (var i = 0; i < pageList.length; i++) {
    for (var j = i + 1; j < pageList.length; j++) {
      if (pageList[i].path == pageList[j].path) {
        pageList.splice(j, 1);
        j--;
      }
    }
  }
  Stroe.commit('setActivePage', to)
  Stroe.dispatch('newPageList', pageList).then(res => { })
  document.title = to.meta.title
})
export default router