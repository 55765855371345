import request from '@/router/axios'

// 获取所有用户列表
export function getUserListApi(query) {
  return request({
    url: '/admin/page',
    method: 'get',
    params: query
  })
}
// 新增或编辑用户信息
export function postEditApi(obj) {
  return request({
    url: '/admin/registerOrUpdate',
    method: 'post',
    data: obj
  })
}

export function deleteApi(id) {
  return request({
    url: `/admin/delete/${id}`,
    method: 'post',
  })
}
