
import { setStroe, getStore } from '../../util/store'
const side = {
  state: {
    userInfo: getStore({
      name: 'userInfo'
    }),
    access_token: getStore({
      name: 'access_token'
    }),
    // access_token
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data
      setStroe({
        name: "userInfo",
        content: data,
        type: 'session'
      })
    },
    setTokenInfo(state, data) {
      state.access_token = data
      setStroe({
        name: "access_token",
        content: data.token,
        type: 'session'
      })
    },
    removeUserInfo(state) {
      state.userInfo = {}
      state.access_token = ''
      setStroe({
        name: "userInfo",
        content: {},
        type: 'session'
      })
      setStroe({
        name: "access_token",
        content: '',
        type: 'session'
      })
    },
    upUserInfo(state, data) {
      state.userInfo.name = data.name
      state.userInfo.avatar = data.avatar
      setStroe({
        name: "userInfo",
        content: state.userInfo,
        type: 'session'
      })
    }
  },
  actions: {
    newTokenInfo({ commit, state }, tokenInfo) {
      return new Promise((resolve, reject) => {
        commit('setTokenInfo', tokenInfo)
        resolve()
      })
    },
    newUserInfo({ commit, state }, info) {
      return new Promise((resolve, reject) => {
        commit('setUserInfo', info)
        resolve()
      })
    },
    removeUserInfo ({ commit, state }, tokenInfo) {
      return new Promise((resolve, reject) => {
        commit('removeUserInfo')
        resolve()
      })
    },
  },
}
export default side