
export const tableOption = {
  border: true,
  index: true,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  align: 'center',
  searchMenuSpan: 6,
  highlightCurrentRow: true,
  menuWidth: 300,
  viewBtn: false,
  delBtn: false,
  addBtn: false,
  tip: false,
  columnBtn: false,
  menu: false,
  searchLabelWidth: 100,
  labelWidth: 100,
  addBtnText: '新增渠道',
  refreshBtn: true,
  column: [
    {
      type: 'inpur',
      label: '活动创建日期',
      prop: 'createTime',
    },
    {
      type: 'input',
      label: '活动id',
      prop: 'id',
    },
    {
      type: 'input',
      label: '活动名称',
      prop: 'activityName',
      search: true
    },
    {
      type: 'input',
      label: '活动编码',
      prop: 'activityCode',
    },
    {
      type: 'input',
      label: '活动链接',
      prop: 'activityUrl',
      slot: true,
      width: 450
    },
  ]
}
