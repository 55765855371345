<template>
  <Layout>
    <Basic-Container>
      <avue-crud
        class="avueCrud"
        ref="crud"
        :page.sync="page"
        :data="tableData"
        @row-save="rowSave"
        @row-update="rowUpdate"
        @search-change="searchChange"
        @size-change="sizeChange"
        @current-change="currentChange"
        @refresh-change="refreshChange"
        :table-loading="tableLoading"
        :option="tableOption"
      >
        <template v-slot:activityUrl="{ row }">
          <el-button type="text" @click="activityUrlClick(row.activityUrl)">{{
            row.activityUrl
          }}</el-button>
        </template>
      </avue-crud>
    </Basic-Container>
  </Layout>
</template>
<script>
import { getActivityListApi } from "@/api/activity/activity.js";
import { tableOption } from "@/views/activity/tableOption.js";
export default {
  name: "activity",
  components: {},
  data() {
    return {
      searchForm: {},
      tableLoading: false,
      tableData: [],
      tableOption: tableOption,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50, 100],
      },
      selectData: {},
    };
  },
  created() {
    this.getList(this.page);
  },
  computed: {},
  methods: {
    getList(page, params) {
      this.tableLoading = true;
      getActivityListApi(
        Object.assign(
          {
            current: page.currentPage,
            size: page.pageSize,
          },
          params,
          this.searchForm
        )
      )
        .then((response) => {
          let data = response.data.data;
          this.tableData = data.records || [];
          this.page.total = Number(data.total) || 0;
          this.tableLoading = false;
        })
        .catch(() => {
          this.$message.error("查询失败");
          this.tableLoading = false;
        });
    },
    searchChange(form, done) {
      if (form.createTime) {
        form.createStartTime = form.createTime[0];
        form.createEndTime = form.createTime[1];
        delete form.createTime;
      }
      this.searchForm = form;
      this.page.currentPage = 1;
      this.getList(this.page, form);
      done();
    },
    sizeChange(pageSize) {
      this.page.currentPage = 1;
      this.page.pageSize = pageSize;
      this.getList(this.page);
    },
    currentChange(current) {
      this.page.currentPage = current;
      this.getList(this.page);
    },
    searchResetFn() {},
    rowSave(form, done, loading) {
      loading();
      let params = JSON.parse(JSON.stringify(form));
      addOrUpdateApi(params)
        .then((res) => {
          this.getList(this.page);
          this.$message.success("新增成功");
          done();
        })
        .catch((err) => {
          this.$message.error("新增失败");
        });
    },
    rowUpdate(form, index, done, loading) {
      let params = JSON.parse(JSON.stringify(form));
      addOrUpdateApi(params)
        .then((res) => {
          this.$message.success("修改成功");
          this.getList(this.page);
          done();
        })
        .catch((err) => {
          loading();
          this.$message.warning("网络错误,请稍候重试");
        });
    },
    refreshChange() {
      this.getList(this.page);
    },
    activityUrlClick(url) {
      this.$Clipboard({
        text: url,
      })
        .then(() => {
          this.$confirm("链接已经复制,是否在新标签页打开此链接?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            window.open(url)
          });
        })
        .catch(() => {
          this.$message.error("复制失败");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>