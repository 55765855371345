
export const tableOption = {
  border: true,
  index: true,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  align: 'center',
  searchMenuSpan: 6,
  highlightCurrentRow: true,
  menuWidth: 300,
  viewBtn: false,
  delBtn: false,
  addBtn: true,
  editBtn: false,
  tip: false,
  columnBtn: false,
  menu: true,
  searchLabelWidth: 100,
  labelWidth: 100,
  addBtn: false,
  refreshBtn: true,
  column: [
    {
      type: 'input',
      label: '客户ID',
      prop: 'id',
      overHidden: true,
      addDisplay: false
    },
    {
      type: 'datetimerange',
      label: '入库时间',
      prop: 'createTime',
      addDisplay: false,
      search: true,
      editDisplay: false,
      searchSpan: 6,
      searchRange: true,
      searchClearable: false,
      defaultTime: ['00:00:00', '23:59:59'],
      format: 'yyyy-MM-dd HH:mm:ss',                  // 这是组件展示的日期格式
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      slot: true,
      width: 100
    },
    {
      type: 'datetimerange',
      label: '客户分配时间',
      prop: 'distributionTime',
      addDisplay: false,
      search: true,
      editDisplay: false,
      searchSpan: 6,
      searchRange: true,
      searchClearable: false,
      defaultTime: ['00:00:00', '23:59:59'],
      format: 'yyyy-MM-dd HH:mm:ss',                  // 这是组件展示的日期格式
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      slot: true,
      width: 100
    },
    {
      type: 'input',
      label: '客户姓名',
      prop: 'name',
      search: true,
      maxlength: 18,
      width: 80
    },
    {
      type: 'select',
      label: '客户性别',
      prop: 'sex',
      search: true,
      width: 80,
      dicData: [
        {
          label: '男',
          value: 0
        },
        {
          label: '女',
          value: 1
        },
        {
          label: '—',
          value: 2
        },
      ]

    },
    {
      type: 'input',
      label: '年龄范围',
      prop: 'ageRange',
      slot: true,
      width: 80,
    },
    {
      type: 'input',
      label: '手机号',
      prop: 'phone',
      search: true,
      maxlength: 18,
      width: 100,
    },
    {
      type: 'input',
      label: '手机号归属地',
      prop: 'ascriptionPlace',
      width: 100,
    },
    {
      type: 'select',
      label: '来源活动',
      prop: 'activityId',
      dicUrl: '/admin/activity/list',
      props: {
        label: 'activityName',
        value: 'id'
      },
      search: true,
      maxlength: 18,
      span: 13,
      width: 80,
    },
    {
      type: 'input',
      label: '来源陵园',
      prop: 'cemeteryName',
      width: 100,
    },
    {
      type: 'input',
      label: '活动链接',
      prop: 'activityUrl',
      slot: true,
      width: 80,
      overHidden: true,
    },
    {
      type: 'select',
      filterable: true,
      label: '来源渠道',
      filterable: true,
      prop: 'channelCode',
      dicUrl: '/admin/channel/list',
      props: {
        label: 'channelName',
        value: 'channelCode'
      },
      dicFormatter: (data) => {
        let newData = data.data
        newData.push({
          channelName: '后台录入-新增表单',
          id: '-1',
          channelCode: '-1'
        })
        return newData
      },
      width: 80,
      slot: true,
      search: true
    },
    {
      type: 'select',
      label: '来源子渠道',
      filterable: true,
      prop: 'subChannelCode',
      dicUrl: '/admin/subChannel/list',
      props: {
        label: 'channelName',
        value: 'channelCode'
      },
      width: 100,
      slot: true,
      search: true,
    },
    {
      type: 'input',
      label: '来源端',
      prop: 'sourceType',
      width: 80,
      slot: true
    },
    {
      type: 'input',
      label: '页面浏览时长',
      prop: 'scanTimeS',
      width: 100
    },
    {
      type: 'input',
      label: '1/4  请问您是给家里哪位亲人看墓？是否需要殡仪服务？',
      prop: 'question1',
      width: 200,
      slot: true
    },
    {
      type: 'input',
      label: '2/4  九公山墓型多样，有标准墓、艺术墓、家族自选墓，价位由1.9万至80万不等；请问您本次购墓预算大概在多少？',
      prop: 'question2',
      width: 300,
      slot: true
    },
    {
      type: 'input',
      label: '3/4  请问您的年龄和性别分别是？',
      prop: 'question3',
      width: 200,
      slot: true
    },
    {
      type: 'input',
      label: '4/4  您什么时间段方便接听电话？',
      prop: 'question4',
      width: 200,
      slot: true
    },
    {
      type: 'input',
      label: '预约墓园',
      prop: 'reservationId',
      width: 100,
      dicData: [{
        value: 1,
        label: "北京九公山纪念林",
      },
      {
        value: 2,
        label: "北京天寿园",
      },
      {
        value: 3,
        label: "北京桃峰园",
      },
      {
        value: 4,
        label: "北京景仰园",
      },
      {
        value: 5,
        label: "北京八达岭陵园",
      },
      {
        value: 6,
        label: "北京凤凰山陵园",
      }, {
        value: 7,
        label: "北京九里山公墓二区",
      }, {
        value: 8,
        label: "北京永宁陵园",
      }, {
        value: 9,
        label: "北京九龙山生态陵园",
      }, {
        value: 10,
        label: "北京归山陵园",
      }, {
        value: 11,
        label: "北京永福公墓",
      }, {
        value: 12,
        label: "北京静安公墓",
      }, {
        value: 13,
        label: "北京龙泉公墓",
      },
      {
        value: 14,
        label: "北京福安园",
      },
      {
        value: 15,
        label: "北京华夏陵园",
      },
      {
        value: 16,
        label: "北京福田公墓",
      },
      {
        value: 17,
        label: "北京佛山陵园",
      },
      {
        value: 18,
        label: "北京炎黄陵园",
      },
      {
        value: 19,
        label: "北京外侨公墓",
      },
      {
        value: 20,
        label: "北京长安园公墓",
      },
      {
        value: 21,
        label: "北京太子峪陵园",
      },
      {
        value: 22,
        label: "北京潮白陵园",
      },
      {
        value: 23,
        label: "北京万佛华侨陵园",
      },
      {
        value: 24,
        label: "北京天堂公墓",
      },
      {
        value: 25,
        label: "北京惠灵山陵园",
      },
      {
        value: 26,
        label: "北京朝阳陵园",
      }, {
        value: 27,
        label: "河北三河灵山宝塔陵园",
      },
      {
        value: 28,
        label: "河北遵化清东陵万佛园",
      },

      {
        value: 29,
        label: "河北涿州人生故事园",
      },
      {
        value: 30,
        label: "河北涿州万佛园公墓",
      },
      {
        value: 31,
        label: "河北涿州祥鹤园公墓",
      },
      {
        value: 32,
        label: "河北涿州卧龙公墓",
      },

      {
        value: 33,
        label: "河北怀来中华永久陵园",
      },
      {
        value: 34,
        label: "河北天佛园公墓",
      },
      {
        value: 35,
        label: "河北廊坊施孝陵园",
      },
      {
        value: 36,
        label: "河北廊坊万桐园公墓",
      },
      {
        value: 37,
        label: "天津玉佛寺寝宫",
      },
      {
        value: 999,
        label: "其他",
      },

      ]
    },
    {
      type: 'input',
      label: '参观时间',
      prop: 'visitTime',
      width: 100
    },
    {
      type: 'input',
      label: '专车时间',
      prop: 'driveTime',
      width: 100,
      slot: true
    },
    {
      type: 'input',
      label: '同行人数',
      prop: 'numberOfPeers',
      width: 100
    },
    {
      type: 'input',
      label: '归属销售',
      prop: 'adminName',
      width: 100
    },
    {
      type: 'input',
      label: '销售账号ID',
      prop: 'adminId',
      width: 100
    },
    // 客户跟进进度,1:新客户,2:已联系,3:已加企微,4:已参观,5:已成交,6:已放弃，默认时1
    {
      type: 'select',
      label: '客户阶段',
      prop: 'progress',
      width: 100,
      search: true,
      dicData: [
        {
          label: '新客户',
          value: 1
        },
        {
          label: '已联系',
          value: 2
        },
        {
          label: '已加企微',
          value: 3
        },
        {
          label: '已参观',
          value: 4
        },
        {
          label: '已成交',
          value: 5
        },
        {
          label: '已放弃',
          value: 6
        },
      ]
    },
    {
      type: 'input',
      label: '客户标签',
      prop: 'clentTag',
      width: 180,
      slot: true,
      overHidden: true,
    },
    {
      type: 'input',
      label: '最新跟进记录',
      prop: 'lastRecord',
      width: 180,
      overHidden: true
    },
    {
      type: 'select',
      label: '客户标签',
      prop: 'tag',
      width: 180,
      addDisplay: false,
      hide: true,
      search: true,

      dicData: [
        {
          value: 1,
          label: '高意向'
        },
        {
          value: 2,
          label: '中意向'
        },
        {
          value: 3,
          label: '低意向'
        },
        {
          value: 4,
          label: '非本人'
        },
        {
          value: 5,
          label: '联系不上'
        },
        {
          value: 6,
          label: '无意向'
        },
        {
          value: 7,
          label: '空号'
        },
        {
          value: 8,
          label: '同行'
        },
        {
          value: 9,
          label: '自己'
        },
        {
          value: 10,
          label: '在世家里人'
        },
        {
          value: 11,
          label: '过世家里人'
        },
        {
          value: 12,
          label: '着急购买'
        },
        {
          value: 13,
          label: '需跟家里人商量'
        },
        {
          value: 14,
          label: '不着急购买'
        },
        {
          value: 15,
          label: '预算充足'
        },
        {
          value: 16,
          label: '预算一般'
        },
        {
          value: 17,
          label: '预算较低'
        },
        {
          value: 18,
          label: '需要殡仪服务'
        },
        {
          value: 19,
          label: '已有选墓经历'
        },
        {
          value: 20,
          label: '同行带看过'
        },
        {
          value: 21,
          label: '继续跟进'
        },
        {
          value: 22,
          label: '放弃跟进'
        },
      ]
    },
    {
      type: 'select',
      label: '归属销售',
      prop: 'adminId',
      dicUrl: '/admin/list',
      width: 120,
      addDisplay: false,
      editDisplay: false,
      hide: true,
      search: true,
      props: {
        label: 'username',
        value: 'id'
      },
    },

  ]
}
