<template>
  <Layout>
    <Basic-Container>
      <avue-crud
        class="avueCrud"
        ref="crud"
        :page.sync="page"
        :data="tableData"
        @row-save="rowSave"
        @row-update="rowUpdate"
        @search-change="searchChange"
        @size-change="sizeChange"
        @current-change="currentChange"
        @refresh-change="refreshChange"
        :table-loading="tableLoading"
        :option="tableOption"
      >
        <template v-slot:menuLeft="{}">
          <el-tabs
            type="border-card"
            v-model="activeName"
            @tab-click="tabClick"
          >
            <el-tab-pane label="全部" name=""></el-tab-pane>
            <el-tab-pane label="PC端" name="pc"></el-tab-pane>
            <el-tab-pane label="移动端" name="mobile"></el-tab-pane>
          </el-tabs>
        </template>
        <template v-slot:date="{ row }">
          <span>{{ row.date }}</span>
        </template>
        <template v-slot:channelId="{ row }">
          <div>{{ row.channelName }}</div>
        </template>
        <template v-slot:sourceType="{ row }">
          <div>
            {{
              row.sourceType == "pc"
                ? "PC端"
                : row.sourceType == "mobile"
                ? "移动端"
                : "--"
            }}
          </div>
        </template>
        <template v-slot:subChannelCode1="{ row }">
          <div>{{ row.subChannelCode }}</div>
        </template>
      </avue-crud>
    </Basic-Container>
  </Layout>
</template>
<script>
import { getChannelDataListApi } from "@/api/channelData/channelData.js";
import { tableOption } from "@/views/channelData/tableOption.js";
export default {
  name: "activity",
  components: {},
  data() {
    return {
      searchForm: {},
      tableLoading: false,
      tableData: [],
      tableOption: tableOption,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50, 100],
      },
      sourceType: "",
      selectData: {},
      activeName: "",
    };
  },
  created() {
    this.getList(this.page);
  },
  computed: {},
  methods: {
    getList(page, params) {
      let newParams = params || [];
      if (this.sourceType) {
        newParams.sourceType = this.sourceType;
      }
      this.tableLoading = true;
      getChannelDataListApi(
        Object.assign(
          {
            current: page.currentPage,
            size: page.pageSize,
          },
          newParams,
          this.searchForm
        )
      )
        .then((response) => {
          let data = response.data.data;
          this.tableData = data.records || [];
          this.page.total = Number(data.total) || 0;
          this.tableLoading = false;
        })
        .catch(() => {
          this.$message.error("查询失败");
          this.tableLoading = false;
        });
    },
    searchChange(form, done) {
      if (form.date) {
        form.startDate = form.date[0].slice(0, 10);
        form.endDate = form.date[1].slice(0, 10);
        delete form.date;
      }
      this.searchForm = form;
      this.page.currentPage = 1;
      this.getList(this.page, form);
      done();
    },
    sizeChange(pageSize) {
      this.page.currentPage = 1;
      this.page.pageSize = pageSize;
      this.getList(this.page);
    },
    currentChange(current) {
      this.page.currentPage = current;
      this.getList(this.page);
    },
    searchResetFn() {},
    rowSave(form, done, loading) {
      loading();
      let params = JSON.parse(JSON.stringify(form));
      addOrUpdateApi(params)
        .then((res) => {
          this.getList(this.page);
          this.$message.success("新增成功");
          done();
        })
        .catch((err) => {
          this.$message.error("新增失败");
        });
    },
    rowUpdate(form, index, done, loading) {
      let params = JSON.parse(JSON.stringify(form));
      addOrUpdateApi(params)
        .then((res) => {
          this.$message.success("修改成功");
          this.getList(this.page);
          done();
        })
        .catch((err) => {
          loading();
          this.$message.warning("网络错误,请稍候重试");
        });
    },
    refreshChange() {
      this.getList(this.page);
    },
    tabClick() {
      this.tableLoading = true;
      this.sourceType = this.activeName == "0" ? "" : this.activeName;
      setTimeout(() => {
        this.getList(this.page);
      }, 500);
    },
  },
};
</script>
<style lang="scss" scoped></style>
